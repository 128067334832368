import React, { useState } from 'react';
import SaveButton from '../Buttons/SaveButton';
import CancelButton from '../Buttons/CancelButton';
import Message from '../../components/Message';

const HistoryForm = ({ initialValues, onSave }) => {
  const [formData, setFormData] = useState(initialValues || {});
  const [originalData, setOriginalData] = useState(initialValues || {}); // New state for original data
  const [isEdited, setIsEdited] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [messageType, setMessageType] = useState('');

  const showMessage = (message, type) => {
    setCurrentMessage(message);
    setMessageType(type);
  };

  const clearMessage = () => {
    setCurrentMessage(null);
  };
  
  const handleCancel = () => {
    setFormData(originalData); // Reset to original data
    setIsEdited(false);
    clearMessage();
  };


  const handleBooleanChange = (e) => {
    if (!isEdited) {
      setOriginalData(formData);
      setIsEdited(true);
    }
    const { name, value } = e.target;
    const updatedValue = value === 'true';

    // Extract the parent object name and the specific field
    const [parent, field] = name.split('.');

    // Update the specific boolean field within the parent object
    const updatedParentData = {
      ...formData[parent],
      [field]: updatedValue,
    };

    if (!updatedValue) {
      // If boolean field is set to false, clear the corresponding details field
      updatedParentData[`${field}.details`] = '';
    }

    setFormData({
      ...formData,
      [parent]: updatedParentData,
    });
  };


  const handleInputChange = (e) => {
    if (!isEdited) {
      setOriginalData(formData);
      setIsEdited(true);
    }
    console.log('originalData', formData, 'formdata', formData);

    const { name, value } = e.target;
    const nameParts = name.split('.');

    if (nameParts.length === 1) {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (nameParts.length > 1) {
      // Handle nested fields (e.g., "medicalConditions.other")
      const [parent, child] = nameParts;
      setFormData({
        ...formData,
        [parent]: {
          ...formData[parent],
          [child]: value,
        },
      });
    }

    console.log('end');
  };


  const renderBooleanField = (fieldPath, label) => {
    const [parent, field] = fieldPath.split('.');
    const booleanValue = formData[parent] ? formData[parent][field] : false;
    return (
      <div className="mb-4 flex">
        <label className="block text-gray-700 text-md mr-5">{label}</label>
        <div className="flex items-center">
          <input
            type="radio"
            name={fieldPath}
            value="true"
            checked={booleanValue === true}
            onChange={handleBooleanChange}
            className="mr-2 "
          />
          <span className="mr-4 text-gray-700">Ναι</span>
          <input
            type="radio"
            name={fieldPath}
            value="false"
            checked={booleanValue === false}
            onChange={handleBooleanChange}
            className="mr-2"
          />
          <span className="mr-4 text-gray-700">Οχι</span>
        </div>
      </div>

    );
  };

  const renderDetailsField = (field) => {
    if (formData[field].option === true) {
      return (
        <div className="ml-5 mb-5">
          <input
            type="text"
            name={`${field}.details`}
            value={formData[field].details || ''}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:shadow-outline"
            placeholder='Παρακαλώ συμπληρώστε...'
          />
        </div>
      );
    }
    return null;
  };

  const renderMealRecallField = (field, label) => (
    <div className="mb-4">
      <label className="block text-gray-700 text-md mb-2">{label}:</label>
      <textarea
        name={`mealRecall.${field}`}
        value={formData.mealRecall[field] || ''}
        onChange={handleInputChange}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
        placeholder={`${label}`}
        rows={2} // You can adjust the number of rows as needed
      />
    </div>
  );


  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    setIsEdited(false);
    showMessage(<span>
      History updated successfully!
    </span>, 'success');

    window.scrollTo(0, 0);
  };

  return (
    <div className="p-4">

      <form onSubmit={handleSubmit}>

        <p className="block text-gray-700 text-md font-bold mb-2">Σωματικό βάρος (Kg)</p>
        <div className="flex flex-row border-l-2 border-orange-500 pl-5 my-4">
          <div className="mr-5">
            <label className="block text-gray-700 text-sm">Χαμηλότερο</label>
            <input type="number" name={`weight.lowest`} value={formData.weight.lowest} onChange={handleInputChange} className="mt-1 text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            <span className="absolute inset-y-0 right-3 flex items-center text-sm">kg</span>

          </div>
          <div className="mr-5">
            <label className="block text-gray-700 text-sm">Σύνηθες</label>
            <input type="number" name="weight.usual" value={formData.weight.usual} onChange={handleInputChange} className="mt-1 text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div className="mr-5">
            <label className="block text-gray-700 text-sm">Υψηλότερο</label>
            <input type="number" name="weight.highest" value={formData.weight.highest} onChange={handleInputChange} className="mt-1 text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
        </div>

        {/* Medical Conditions */}

        <p className="block text-gray-700 text-md mb-2 font-bold mt-8">Ιατρικές Παθήσεις</p>
        <div className="border-l-2 border-orange-500 pl-5 my-4">
          {renderBooleanField("cardiovascularProblems.option", "Καρδιαγγειακά πρόβλημα:")}
          {renderDetailsField("cardiovascularProblems")}
          {renderBooleanField("diabetesProblems.option", "Διαβήτης:")}
          {renderDetailsField("diabetesProblems")}
          {renderBooleanField("highBloodPressureProblems.option", "Υψηλή πίεση αίματος:")}
          {renderDetailsField("highBloodPressureProblems")}

          <div className="mb-4 flex items-center">
            <label className="block text-gray-700 text-sm mr-5">Άλλο:</label>
            <input type="text" name="medicalConditionsOthers" value={formData.medicalConditionsOthers} onChange={handleInputChange} placeholder='Παρακαλώ συμπληρώστε αν υπάρχουν άλλες ιατρικές παθήσεις...' className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:shadow-outline" />
          </div>
        </div>

        <p className="block text-gray-700 text-md font-bold mb-2 mt-8">Διάφορα</p>

        <div className="border-l-2 border-orange-500 pl-5 my-4">
          {renderBooleanField("medication.option", "Λαμβάνετε φαρμακευτική αγωγή;")}
          {renderDetailsField("medication")}

          {renderBooleanField("stomachIntestinalProblems.option", "Προβλήματα με στομάχι/έντερο;")}
          {renderDetailsField("stomachIntestinalProblems")}

          {renderBooleanField("dietarySupplements.option", "Λαμβάνετε συμπληρώματα διατροφής;")}
          {renderDetailsField("dietarySupplements")}

          {renderBooleanField("difficultyFollowingDiet", "Δυσκολία ακολούθησης ενός πρόγραμματος διατροφής;")}

          {renderBooleanField("smoking", "Καπνίζετε;")}

          {renderBooleanField("alcoholConsumption.option", "Καταναλώνετε αλκοόλ;")}
          {renderDetailsField("alcoholConsumption")}

          {renderBooleanField("foodAllergy.option", "Έχετε αλλεργία σε κάποιο τρόφιμο;")}
          {renderDetailsField("foodAllergy")}

          {renderBooleanField("foodDislike.option", "Έχετε απέχθεια σε κάποιο τρόφιμο;")}
          {renderDetailsField("foodDislike")}

          {renderBooleanField("foodPreference.option", "Έχετε προτίμηση σε κάποιο τρόφιμο;")}
          {renderDetailsField("foodPreference")}
        </div>

        <p className="block text-gray-700 text-md font-bold mb-2 mt-8">Περιγράψτε είδος και επίπεδο φυσικής δραστηριότητας</p>
        <div className="border-l-2 border-orange-500 pl-5 my-4">
          <textarea
            name="physicalActivityDescription"
            value={formData.physicalActivityDescription}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
            rows={2} // You can adjust the number of rows as needed
            placeholder='Παρακαλώ περιγράψτε είδος και επίπεδο φυσικής δραστηριότητας'
          />
        </div>


        {/* Meal Recall Fields */}
        <p className="block text-gray-700 text-md font-bold mb-2 mt-8">Ανάκληση 24ωρου</p>
        <div className="border-l-2 border-orange-500 pl-5 my-4">

          {renderMealRecallField("breakfast", "Πρωινό")}
          {renderMealRecallField("brunch", "Δεκατιανό")}
          {renderMealRecallField("lunch", "Μεσημεριανό")}
          {renderMealRecallField("afternoonSnack", "Απογευματινό")}
          {renderMealRecallField("dinner", "Βραδινό")}
          {renderMealRecallField("sweets", "Κατανάλωση Γλυκών")}
          {renderMealRecallField("waterIntake", "Κατανάλωση Νερού")}
          {renderMealRecallField("juiceSodaIntake", "Κατανάλωση Χυμών/Αναψυκτικών")}
          {/* ... other sections ... */}
        </div>

        <p className="block text-gray-700 text-md font-bold mb-2 mt-8">Σημειώσεις</p>
        <div className="border-l-2 border-orange-500 pl-5 my-4">
          <div className="mb-4">
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
              rows={4} // You can adjust the number of rows as needed
              placeholder='Γράψτε τις σημειώσεις για το ιστορικό...'
            />
          </div>
        </div>
        <div className="fixed bottom-8 right-8 flex gap-4">
          <CancelButton onClick={handleCancel} visibility={isEdited} />
          <SaveButton onClick={handleSubmit} visibility={isEdited} />
        </div>
      </form>
      <Message
        message={currentMessage}
        type={messageType}
        onDismiss={clearMessage}
      />
    </div>
  );
};



export default HistoryForm;

import React, { useState } from 'react';
import { FaSave } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import Message from '../../components/Message';

const ClientEditForm = ({ client, onSave, onCancel, onChange }) => {
  const [currentMessage, setCurrentMessage] = useState(null);
  const [messageType, setMessageType] = useState('');
  
  const showMessage = (message, type) => {
    setCurrentMessage(message);
    setMessageType(type);
};

const clearMessage = () => {
    setCurrentMessage(null);
};

  const handleFloatInputChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value) && value >= 0) {
      onChange(e);
    }
  };

  const handleSave = () => {
    // Manual validation
    if (!client.name || !client.status || !client.gender || !client.birthday || !client.height) {
      showMessage(<span>
        Το όνομα, η κατάσταση, το φύλο, η ημερ/νια γέννησης ή το ύψος είναι κενό, παρακαλώ συμπληρώστε!
    </span>, 'error');
      return;
    }
    onSave();
  };

  return (
    <>
      <td className="px-3 py-2">
        <input type="text" name="name" className="w-full p-2 border rounded" value={client.name} onChange={onChange} required />
      </td>
      <td className="px-3 py-2">
        <select name="status" className="w-full p-2 border rounded" value={client.status} onChange={onChange}>
          <option value="" >Select Status</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
      </td>
      <td className="px-3 py-2">
        <select name="gender" className="w-full p-2 border rounded" value={client.gender} onChange={onChange}>
          <option value="" >Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </td>
      <td className="px-3 py-2">
        <input type="date" name="birthday" className="w-full p-2 border rounded" value={client.birthday} onChange={onChange} />
      </td>
      <td className="px-3 py-2">
        <input type="number" name="height" className="w-full p-2 border rounded" value={client.height} onChange={handleFloatInputChange} min="0" step="1" />
      </td>
      <td className="px-3 py-2">
        <input type="number" name="target" className="w-full p-2 border rounded" value={client.target} onChange={handleFloatInputChange} min="0" step="0.1" />
      </td>
      <td className="px-3 py-2">
        <input type="tel" pattern="[0-9]*" name="phone" className="w-full p-2 border rounded" value={client.phone} onChange={onChange} />
      </td>
      <td className="px-3 py-2">
        <input type="email" name="email" className="w-full p-2 border rounded" value={client.email} onChange={onChange} />
      </td>
      <td className="px-3 py-2">
        <input type="text" name="address" className="w-full p-2 border rounded" value={client.address} onChange={onChange} />
      </td>
      <td>
        <div className='flex'>
          <button className="mx-2 p-2 border rounded bg-red-500 text-white" onClick={onCancel}><MdCancel size={20} /></button>
          <button className="mr-2 p-2 border rounded bg-orange-500 text-white" onClick={handleSave}><FaSave size={20} /></button>
        </div>
        <Message
                message={currentMessage}
                type={messageType}
                onDismiss={clearMessage}
            />
      </td>
    </>
  );
};

export default ClientEditForm;

import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import { AiOutlineMail } from 'react-icons/ai'; // Icon for email
import { RiLockPasswordFill } from 'react-icons/ri'; // Icon for password
import { IoMdClose } from 'react-icons/io'; // Icon for close button

const LoginModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      onClose();
    } catch (error) {
      setError('Το email ή κωδικός πρόσβασης είναι λάθος');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-30">
      <div className="relative bg-white p-12 rounded-lg shadow-lg">
        <button onClick={onClose} className="absolute top-3 right-3 text-xl">
          <IoMdClose />
        </button>

        <h2 className="text-2xl font-medium text-center mb-8">Σύνδεση</h2> {/* Title */}

        <form onSubmit={handleLogin} className="flex flex-col space-y-4">
          <div className="flex items-center border-b border-gray-300">
            <span className="bg-orange-500 text-white p-2 rounded-tl rounded-bl">
              <AiOutlineMail />
            </span>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="flex-1 px-3 py-2 w-72 bg-transparent focus:outline-none"
            />
          </div>
          <div className="flex items-center border-b border-gray-300">
            <span className="bg-orange-500 text-white p-2 rounded-tl rounded-bl">
              <RiLockPasswordFill />
            </span>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Κωδικός"
              className="flex-1 px-3 py-2 bg-transparent focus:outline-none"
            />
          </div>
          <button
            type="submit"
            className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded" // Increased padding
          >
            Σύνδεση
          </button>
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default LoginModal;

import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { collection, addDoc, deleteDoc, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { MdDelete } from "react-icons/md";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const MealsForm = () => {
    const [meals, setMeals] = useState({ breakfast: [], lunch: [], snack: [] });
    const [editingMealId, setEditingMealId] = useState(null);
    const [originalMeal, setOriginalMeal] = useState(null);

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', { 'background': [] }], // toggled buttons
        ]
    };

    const formats = [
        'bold', 'italic', 'underline', 'background'
    ];
    const mealTitles = {
        breakfast: "Πρωινό",
        lunch: "Μεσημεριανό",
        snack: "Σνακ"
    };

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(firestore, "meals"), (snapshot) => {
            const mealsData = { breakfast: [], lunch: [], snack: [] };
            snapshot.forEach(doc => {
                const data = doc.data();
                mealsData[data.type].push({ ...data, id: doc.id });
            });
            setMeals(mealsData);
        });

        return () => unsubscribe();
    }, []);

    const handleAddMeal = (type) => {
        const tempMeal = { id: 'new', type, description: '' };
        setMeals({ ...meals, [type]: [...meals[type], tempMeal] });
        setEditingMealId('new');
        setOriginalMeal({ ...tempMeal });
    };

    const handleCancelEdit = (type) => {
        if (editingMealId === 'new') {
            // Cancel adding a new meal
            setMeals({ ...meals, [type]: meals[type].filter(meal => meal.id !== 'new') });
        } else if (originalMeal) {
            // Revert to the original meal data
            setMeals({ ...meals, [type]: meals[type].map(m => m.id === editingMealId ? originalMeal : m) });
        }
        setEditingMealId(null);
        setOriginalMeal(null);
    };


    const handleSaveMeal = async (type, meal) => {
        if (meal.id === 'new') {
            try {
                const newMealRef = await addDoc(collection(firestore, 'meals'), {
                    type: type,
                    description: meal.description
                });
                const updatedMeals = meals[type].map(m => m.id === 'new' ? { ...m, id: newMealRef.id } : m);
                setMeals({ ...meals, [type]: updatedMeals });
            } catch (error) {
                console.error('Error adding new meal:', error);
            }
        } else {
            try {
                await updateDoc(doc(firestore, 'meals', meal.id), { description: meal.description });
            } catch (error) {
                console.error('Error updating meal:', error);
            }
        }
        setEditingMealId(null);
        setOriginalMeal(null);
    };

    const handleMealChange = (type, mealId, newDescription) => {
        if (editingMealId !== mealId) {
            // When starting to edit a different meal, store its original state
            const original = meals[type].find(m => m.id === mealId);
            setOriginalMeal(original ? { ...original } : null);
        }
        setEditingMealId(mealId);
        const updatedMeals = meals[type].map(meal => {
            if (meal.id === mealId) {
                return { ...meal, description: newDescription };
            }
            return meal;
        });
        setMeals({ ...meals, [type]: updatedMeals });
    };




    const handleDeleteMeal = async (type, mealId) => {
        try {
            await deleteDoc(doc(firestore, 'meals', mealId));
            setMeals({ ...meals, [type]: meals[type].filter(meal => meal.id !== mealId) });
        } catch (error) {
            console.error('Error deleting meal:', error);
        }
    };

    const isEditing = editingMealId !== null;

    return (
        <div className="container mx-auto p-4">
            {Object.keys(meals).map((type) => (
                <div key={type} className="mb-12">
                    <div className='flex flex-row items-center'>
                        <h2 className="text-2xl font-medium text-orange-500 mb-2">{mealTitles[type]}</h2>
                        <button
                            onClick={() => handleAddMeal(type)}
                            disabled={isEditing}  // Disable this button when editing
                            className={`ml-4 text-sm ${isEditing ? 'bg-gray-400' : 'bg-green-500'} text-white font-semibold px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150`}
                        >
                            Προσθήκη
                        </button>
                    </div>
                    <div className="flex flex-wrap border-l-2 border-orange-500">
                        {meals[type].map((meal) => (
                            <div key={meal.id} className="p-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6">
                                <div className="border border-gray-300 rounded-md p-2 mb-4">
                                    <ReactQuill
                                        value={meal.description}
                                        onChange={(content) => handleMealChange(type, meal.id, content)}
                                        readOnly={isEditing && editingMealId !== meal.id}
                                        modules={modules}
                                        formats={formats}
                                        className="react-quill-editor border-b border-gray-400 rounded bg-white text-sm mb-2"
                                    />
                                    <div className="flex justify-between">
                                        {meal.id !== 'new' && (
                                            <button
                                                onClick={() => handleDeleteMeal(type, meal.id)}
                                                className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-2 rounded-md shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                                            >
                                                <MdDelete size={16} />
                                            </button>
                                        )}
                                        {editingMealId === meal.id && (
                                            <>
                                                <button
                                                    onClick={() => handleCancelEdit(type)}
                                                    className="bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    onClick={() => handleSaveMeal(type, meal)}
                                                    className="bg-orange-500 text-white active:bg-orange-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                                                >
                                                    Save
                                                </button>

                                            </>
                                        )}
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MealsForm;

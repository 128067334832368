import React from 'react';
import { MdEdit } from "react-icons/md";

const RowEditButton = ({ onEdit, isDisabled }) => {

  return (
    <button
      className={`mx-2 my-2 rounded p-2 ${isDisabled ? 'bg-gray-400 text-white cursor-not-allowed' : 'bg-orange-500 text-white'}`}
      onClick={onEdit}
      disabled={isDisabled}
    >
      <MdEdit size={20} />
    </button>
  );
};

export default RowEditButton;

import React from 'react';
import { MdDelete } from "react-icons/md";

const RowDeleteButton = ({ onDelete, id, isDisabled }) => {

  return (
<button
  className={`mx-2 my-2 p-2 rounded text-white ${isDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-600'}`}
  onClick={() => isDisabled ? null : onDelete(id)}
  disabled={isDisabled}
>
  <MdDelete size={20} />
</button>
    );
};

export default RowDeleteButton;

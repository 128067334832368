import React, { useState, useEffect } from 'react';
import SaveButton from '../Buttons/SaveButton';
import CancelButton from '../Buttons/CancelButton';
import Message from '../../components/Message';

const ClientProfileForm = ({ initialValues, onSave }) => {
    const [formData, setFormData] = useState(initialValues || {});
    const [originalData, setOriginalData] = useState(initialValues || {}); // New state for original data
    const [isEdited, setIsEdited] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [messageType, setMessageType] = useState('');

    const showMessage = (message, type) => {
        setCurrentMessage(message);
        setMessageType(type);
    };

    const clearMessage = () => {
        setCurrentMessage(null);
    };

    const handleCancel = () => {
        setFormData(originalData); // Reset to original data
        setIsEdited(false);
        clearMessage();
    };

    useEffect(() => {
        setFormData(initialValues || {});
    }, [initialValues]);

    const handleInputChange = (e) => {
        if (!isEdited) {
            setOriginalData(formData);
            setIsEdited(true);
        }
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onSave(formData);
        setIsEdited(false);
        showMessage(<span>
            Το ιστορικό του {formData.name} ενημερώθηκε!
        </span>, 'success');
        window.scrollTo(0, 0);

    };

    const classNames = "mt-1 shadow appearance-none border rounded w-full py-2 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"

    return (
        <div className="p-4">
            <Message
                message={currentMessage}
                type={messageType}
                onDismiss={clearMessage}
            />
            <form onSubmit={handleSubmit}>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Ονοματεπώνυμο</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name || ''}
                            onChange={handleInputChange}
                            className={classNames}

                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Κατάσταση</label>
                        <select
                            name="status"
                            value={formData.status || ''}
                            onChange={handleInputChange}
                            className={`${classNames} ${formData.status === 'Active' ? 'text-green-600' : formData.status === 'Inactive' ? 'text-red-600' : ''}`}

                        >
                            <option value="" className='text-gray-700 bg-gray-50'>Επέλεξε Κατάσταση</option>
                            <option value="Active" className='text-green-600 bg-gray-50'>Active</option>
                            <option value="Inactive" className='text-red-600 bg-gray-50' >Inactive</option>
                        </select>
                    </div>

                    {/* Height */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Ύψος</label>
                        <input
                            type="number"
                            name="height"
                            value={formData.height || ''}
                            onChange={handleInputChange}
                            className={classNames}
                        />
                    </div>

                    {/* Target Weight */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Στόχος Βάρους</label>
                        <input
                            type="number"
                            name="target"
                            value={formData.target || ''}
                            onChange={handleInputChange}
                            className={classNames}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email || ''}
                            onChange={handleInputChange}
                            className={classNames}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Τηλέφωνο</label>
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone || ''}
                            onChange={handleInputChange}
                            className={classNames}
                        />
                    </div>

                    {/* Address Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Διεύθυνση</label>
                        <input
                            type="text"
                            name="address"
                            value={formData.address || ''}
                            onChange={handleInputChange}
                            className={classNames}
                        />
                    </div>

                    {/* Gender Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Φύλο</label>
                        <select
                            name="gender"
                            value={formData.gender || ''}
                            onChange={handleInputChange}
                            className={`${classNames} ${formData.gender === 'Male' ? 'text-blue-500' : formData.gender === 'Female' ? 'text-red-400' : ''}`}
                        >
                            <option value="" className='text-gray-700 bg-gray-50'>Επέλεξε Φύλο</option>
                            <option value="Male" className='text-blue-500 bg-gray-50'>Male</option>
                            <option value="Female" className='text-red-400 bg-gray-50'>Female</option>
                        </select>
                    </div>

                    {/* Birthday Field */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Ημ. Γέννησης</label>
                        <input
                            type="date"
                            name="birthday"
                            value={formData.birthday || ''}
                            onChange={handleInputChange}
                            className={classNames}
                        />
                    </div>
                </div>

                <div className="fixed bottom-8 right-8 flex gap-4">
                    <CancelButton onClick={handleCancel} visibility={isEdited} />
                    <SaveButton onClick={handleSubmit} visibility={isEdited} />
                </div>
            </form>
        </div>
    );
};

export default ClientProfileForm;

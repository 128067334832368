import React from 'react';

const SuccessMessage = ({ message, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="py-1 mb-2 w-fix text-green-600">
      {message}
    </div>
  );
};

export default SuccessMessage;

import React from 'react';

const SaveButton = ({ onClick, visibility }) => {
  if (!visibility) return null;

  return (
    <div className="flex justify-end mt-4">
      <button
        type="submit"
        onClick={onClick}
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none"
      >
        Save
      </button>
    </div>
  );
};

export default SaveButton;

import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

// Fetch clients
export const fetchClients = async () => {
  const clientsCollectionRef = collection(firestore, 'Clients');
  const snapshot = await getDocs(clientsCollectionRef);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// Update client
export const updateClient = async (id, data) => {
  const clientDocRef = doc(firestore, "Clients", id);
  await updateDoc(clientDocRef, data);
};

// Delete client
export const deleteClient = async (id) => {
  const clientDocRefClients = doc(firestore, "Clients", id);
  await deleteDoc(clientDocRefClients);

  const clientDocRefEquivalents = doc(firestore, "Equivalents", id);
  await deleteDoc(clientDocRefEquivalents);

  const clientDocRefHistory = doc(firestore, "History", id);
  await deleteDoc(clientDocRefHistory);
};

// Add new client
export const addClient = async (data) => {
  const newClientRef = await addDoc(collection(firestore, "Clients"), data);

  // Define the default equivalents data
  // const equivalentsData = {
  //   targetCalories: 1700,
  //   target_carbohydrates_percent : 40,
  //   target_proteins_percent : 30,
  //   target_fat_percent : 30,
  //   full_milk: 0,
  //   semi_milk: 0,
  //   zero_milk: 0,
  //   fruits: 0,
  //   vegetables: 0,
  //   bread_cereals: 0,
  //   full_meat: 0,
  //   semi_meat: 0,
  //   zero_meat: 0,
  //   fat: 0,
  //   clientId: newClientRef.id
  // };

  const defaultHistoryData = {
    weight: {
      lowest: '',
      usual: '',
      highest: ''
    },
    cardiovascularProblems: {
      option: false,
      details: ''
    },
    diabetesProblems: {
      option: false,
      details: ''
    },
    highBloodPressureProblems: {
      option: false,
      details: ''
    },
    medicalConditionsOthers: '',
    medication: {
      option: false,
      details: ''
    },
    stomachIntestinalProblems: {
      option: false,
      details: ''
    },

    difficultyFollowingDiet: false,
    smoking: false,
    dietarySupplements: {
      option: false,
      details: ''
    },
    alcoholConsumption: {
      option: false,
      details: ''
    },
    foodAllergy: {
      option: false,
      details: ''
    },
    foodDislike: {
      option: false,
      details: ''
    },
    foodPreference: {
      option: false,
      details: ''
    },
    physicalActivityDescription: '',
    mealRecall: {
      breakfast: '',
      brunch: '',
      lunch: '',
      afternoonSnack: '',
      dinner: '',
      sweets: '',
      waterIntake: '',
      juiceSodaIntake: ''
    },
    notes: '',
    clientId: newClientRef.id // Link to the client
  };

  // Create a new document in the History collection with default data
  // await setDoc(doc(firestore, "Equivalents", newClientRef.id), equivalentsData);
  await setDoc(doc(firestore, "History", newClientRef.id), defaultHistoryData);

  return newClientRef.id;
};

import React, { useState } from 'react';
import LoginModal from '../../LoginModal';
import { useAuth } from '../Auth/AuthProvider';
import logo from '../../assets/logo.png';
import { auth } from '../../firebase';
import { FiUser } from 'react-icons/fi';
import { FaBars, FaTimes } from 'react-icons/fa';

const handleClick = (e) => {
  e.preventDefault();
  const services = document.getElementById('services');
  if (services) {
    window.scrollTo({
      top: services.offsetTop,
      behavior: 'smooth'
    });
  }
};

const Header = () => {
  const { currentUser, loading } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (err) {
      console.error('Error signing out', err);
    }
  };

    // Function to get the current path
    const getCurrentPath = () => window.location.pathname;

    // Function to determine if the nav item is active
    const isActive = (path) => getCurrentPath() === path;

  return (
    <>
      <header className="bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg p-3 flex justify-between items-center px-10 md:px-20 relative z-10">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="h-10 lg:h-16 w-full object-contain mr-3" />
        </div>

        <nav className={`${isMenuOpen ? 'flex bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg md:bg-transparent' : 'hidden lg:flex'} justify-center text-md text-center absolute lg:relative top-full left-0 w-full z-20 text-orange-500 text-md font-medium`}>
          <ul className="lg:flex">
          <a href="/" className={`px-4 py-2 rounded mx-1 ${isActive('/') ? 'bg-orange-500 text-white' : 'hover:bg-orange-500 hover:text-white'}`}>Αρχική</a>
          <a href="#services" onClick={handleClick} className={`px-4 py-2 mx-1 rounded ${isActive('/services') ? 'bg-orange-500 text-white' : 'hover:bg-orange-500 hover:text-white'}`}>Υπηρεσίες</a>
            {/* <li className="p-2"><a href="#contact" className="px-4 py-2 rounded hover:bg-orange-500 hover:text-white">Επικοινωνία</a></li> */}
            {currentUser && (
              <li className="p-2"><a href="/workspace" className={`px-4 py-2 mx-1 rounded text-green-600 ${isActive('/workspace') ? 'bg-green-600 text-white' : 'hover:bg-green-600 hover:text-white'}`}>Workspace</a></li>
            )}
            {!loading && (
              <li className="p-2 lg:hidden">
                {currentUser ? (
                  <>
                    {/* <span>{currentUser.email}</span> */}
                    <button onClick={handleLogout} className="text-sm px-4 py-2 border border-transparent text-md font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600">
                      Αποσύνδεση
                    </button>
                  </>
                ) : (
                  <button onClick={() => setShowModal(true)} className="text-sm px-4 py-2 border border-transparent text-md font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600">
                    Σύνδεση
                  </button>
                )}
              </li>
            )}
          </ul>
        </nav>

        <div className="flex items-center space-x-4">
          {!loading && currentUser && (
            <div className="hidden lg:flex relative group flex items-center">
              <FiUser className="text-xl cursor-pointer mr-5" />
              <div className="absolute top-full left-0 bg-white px-2 py-1 text-sm rounded shadow-md opacity-0 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                {currentUser.email}
              </div>
              <button onClick={handleLogout} className="text-sm inline-flex items-center justify-center px-4 py-2 border border-transparent text-md font-medium rounded-md text-white bg-red-500 hover:bg-red-600">
              Αποσύνδεση
              </button>
            </div>
          )}

          {!currentUser && (
            <button className="text-sm hidden lg:inline-flex items-center justify-center px-4 py-2 border border-transparent text-md font-medium rounded-md text-white bg-green-600 hover:bg-green-700" onClick={() => setShowModal(true)}>
              Σύνδεση
            </button>
          )}

          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-xl focus:outline-none text-orange-500 lg:hidden">
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </header>
      {showModal && <LoginModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export default Header;

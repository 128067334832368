import React, { useState } from 'react';
import { TiPlus } from "react-icons/ti";
import { firestore } from '../../firebase';
import { collection, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { FaSave } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import RowEditButton from '../Buttons/RowEditButton';
import RowDeleteButton from '../Buttons/RowDeleteButton';
import { formatDate, calculate_bmr_mbi_tee } from '../../functions/Functions';
import EquivalentsFormPopup from './EquivalentsFormPopup';
import Message from '../../components/Message';

const MeasurementsForm = ({ clientId, clientdata, measurements, setMeasurements }) => {
    const [editingId, setEditingId] = useState(null);
    const [showEquivalentsPopup, setShowEquivalentsPopup] = useState(false);
    const [EquivalentsEditId, setEquivalentsEditId] = useState('');
    const [EquivalentsEdit, setEquivalentsEdit] = useState({});
    const [editFormData, setEditFormData] = useState({
        date: '',
        activityFactor: '',
        weight: '',
        fat: '',
        liquids: '',
        notes: '',
    });

    const [currentMessage, setCurrentMessage] = useState(null);
    const [messageType, setMessageType] = useState('');

    const showMessage = (message, type) => {
        setCurrentMessage(message);
        setMessageType(type);
    };

    const clearMessage = () => {
        setCurrentMessage(null);
    };

    // Define the default equivalents data
    const equivalentsData = {
        targetCalories: 1700,
        target_carbohydrates_percent: 40,
        target_proteins_percent: 30,
        target_fat_percent: 30,
        full_milk: 0,
        semi_milk: 0,
        zero_milk: 0,
        fruits: 0,
        vegetables: 0,
        bread_cereals: 0,
        full_meat: 0,
        semi_meat: 0,
        zero_meat: 0,
        fat: 0,
    };


    const handleInputChange = (e) => {
        setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
    };


    const handleEditClick = (measurement) => {
        setEditingId(measurement.id);
        setEditFormData(measurement);
    };

    const handleSaveClick = async () => {
        if (!editFormData.date || editFormData.date.trim() === '' || editFormData.weight === '' || editFormData.activityFactor === '') {
            showMessage(<span>
                Παρακαλώ συμπληρώστε τουλάχιστον ημ/νια, συντελεστή δραστηριότητας και βάρος!
            </span>, 'error');
            return;
        }
        try {
            if (editingId === 'new') {

                const clientDocRef = doc(firestore, "Clients", clientId);
                const measurementsRef = collection(clientDocRef, "Measurements");
                const docRef = await addDoc(measurementsRef, { ...editFormData, clientId });
                const newId = docRef.id;

                setMeasurements(prevMeasurements =>
                    prevMeasurements.map(m => (m.id === 'new' ? { ...editFormData, id: newId } : m))
                );
                const measurementRef = doc(firestore, `Clients/${clientId}/Measurements`, newId);
                await updateDoc(measurementRef, { id: newId });
                showMessage(<span>
                    Η μέτρηση {editFormData.date} προστέθηκε.
                </span>, 'success');

            } else {
                const measurementRef = doc(firestore, `Clients/${clientId}/Measurements`, editingId);
                await updateDoc(measurementRef, editFormData);

                setMeasurements(prevMeasurements =>
                    prevMeasurements.map(m => (m.id === editingId ? { ...editFormData, id: editingId } : m))
                );
                showMessage(<span>
                    Η μέτρηση {editFormData.date} ενημερώθηκε.
                </span>, 'success');
            }

            setEditingId(null);
            setEditFormData({});
        } catch (error) {
            console.error('Error saving measurement:', error);
        }
    };

    const handleCancelClick = () => {
        if (editingId === 'new') {
            // Remove the temporary new measurement entry
            setMeasurements(measurements.filter(measurement => measurement.id !== 'new'));
        }
        setEditingId(null);
        setEditFormData({});
    };

    const handleDelete = async (measurementId) => {
        try {

            const measurementRef = doc(firestore, `Clients/${clientId}/Measurements`, measurementId);
            await deleteDoc(measurementRef);
            // Update the measurements state by filtering out the deleted measurement
            setMeasurements(prevMeasurements =>
                prevMeasurements.filter(m => m.id !== measurementId)
            );

        } catch (error) {
            console.error('Error deleting measurement:', error);
        }
    };

    const handleAddMeasurement = () => {
        const today = new Date().toISOString().split('T')[0];

        // Start with default equivalents
        let initialEquivalents = { ...equivalentsData };

        // Check if there are existing measurements
        if (measurements && measurements.length > 0) {
            // Find the most recent measurement
            const latestMeasurement = measurements[measurements.length - 1];

            // Check if the latest measurement has equivalent values
            if (latestMeasurement && latestMeasurement.equivalents) {
                initialEquivalents = { ...latestMeasurement.equivalents };
            }
        }

        const newMeasurement = {
            id: 'new', // Temporary ID
            date: today,
            activityFactor: '',
            weight: '',
            fat: '',
            liquids: '',
            notes: '',
            equivalents: initialEquivalents
        };

        setMeasurements([...measurements, newMeasurement]);
        setEditingId('new');
        setEditFormData(newMeasurement);
    };


    const handleEditEquivalentsClick = async (measurement, measurementid) => {
        setEquivalentsEditId(measurementid);
        setEquivalentsEdit(measurement);
        setShowEquivalentsPopup(true);
    };

    const renderMeasurementRow = (measurement) => {

        const isDisabled = editingId === 'new' || (editingId !== null && editingId !== measurement.id);

        const bmr_bmi_ib_tee = calculate_bmr_mbi_tee(clientdata, measurement.weight, measurement.activityFactor);
        let bmr = bmr_bmi_ib_tee[0];
        let bmi = bmr_bmi_ib_tee[1];
        let tee = bmr_bmi_ib_tee[2];

        return (
            <>
                <Message
                    message={currentMessage}
                    type={messageType}
                    onDismiss={clearMessage}
                />
                <tr key={measurement.id} className="text-sm border-t border-gray-300">
                    <td className="px-3 py-2 whitespace-nowrap">{formatDate(measurement.date)}</td>
                    <td className="px-3 py-2 whitespace-nowrap">{measurement.activityFactor}</td>
                    <td className="px-3 py-2 whitespace-nowrap">{measurement.weight}</td>
                    <td className="px-3 py-2 whitespace-nowrap">{measurement.fat}</td>
                    <td className="px-3 py-2 whitespace-nowrap">{measurement.liquids}</td>
                    <td className="px-3 py-2 whitespace-nowrap">{bmr}</td>
                    <td className="px-3 py-2 whitespace-nowrap">{bmi}</td>
                    <td className="px-3 py-2 whitespace-nowrap">{tee}</td>
                    <td>
                        <div className="flex">
                            <RowEditButton onEdit={() => handleEditClick(measurement)} isDisabled={isDisabled} />
                            <RowDeleteButton onDelete={handleDelete} id={measurement.id} isDisabled={isDisabled} />
                        </div>
                    </td>
                </tr>
                <tr className='text-sm'>
                    <td colspan="8" className='px-3 pb-2 whitespace-nowrap'>
                        <td className="text-sm italic text-gray-700">{measurement.notes}</td>
                    </td>
                    <td>
                        <div className="flex">
                            <button
                                className={`mx-2 my-2 p-2 rounded text-white text-sm ${isDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-orange-500'}`}
                                disabled={isDisabled}
                                onClick={() => handleEditEquivalentsClick(measurement, measurement.id)}
                            >
                                Ισοδύναμα
                            </button>
                        </div>
                    </td>
                </tr>
            </>
        );
    };

    const handleSaveEquivalents = async (updatedEquivalents) => {
        try {
            if (EquivalentsEditId) {
                // Assuming 'EquivalentsEditId' is the ID of the measurement document where equivalents need to be updated
                const measurementRef = doc(firestore, `Clients/${clientId}/Measurements`, EquivalentsEditId);

                // Update the 'equivalents' field of the measurement document
                await updateDoc(measurementRef, { equivalents: updatedEquivalents });

                // Update the local state to reflect the changes
                setMeasurements(prevMeasurements =>
                    prevMeasurements.map(measurement =>
                        measurement.id === EquivalentsEditId ? { ...measurement, equivalents: updatedEquivalents } : measurement
                    )
                );

                // Optionally, close the popup after saving
                setShowEquivalentsPopup(false);
                showMessage(<span>
                    Τα Ισοδύναμα της μέτρησης ενημερώθηκε!
                  </span>, 'success');
                console.log('Equivalents data updated successfully');
            }
        } catch (error) {
            console.error('Error updating equivalents:', error);
        }
    };


    const handleCancelEquivalents = () => {
        // Close the popup
        setShowEquivalentsPopup(false);
    };

    const renderForm = () => {

        const bmr_bmi_ib_tee = calculate_bmr_mbi_tee(clientdata, editFormData.weight, editFormData.activityFactor);
        let bmr = bmr_bmi_ib_tee[0];
        let bmi = bmr_bmi_ib_tee[1];
        let tee = bmr_bmi_ib_tee[2];
        return (
            <>
                <tr className="text-sm border-t border-gray-300" key={editFormData.id}>
                    <td className="px-3 py-2">
                        <input type="date" name="date" value={editFormData.date} onChange={handleInputChange} className="w-full p-2 border rounded" required />
                    </td>
                    <td className="px-3 py-2">
                        <input
                            type="number"
                            name="activityFactor"
                            value={editFormData.activityFactor}
                            onChange={handleInputChange}
                            min="1.1"
                            max="2.4"
                            step="0.1"
                            className="w-full p-2 border rounded"
                        />
                    </td>
                    <td className="min-w-24 px-3 py-2">
                        <input
                            type="number"
                            name="weight"
                            value={editFormData.weight}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded"
                        />
                    </td>
                    <td className="min-w-24 px-3 py-2">
                        <input type="number" name="fat" value={editFormData.fat} onChange={handleInputChange} className="w-full p-2 border rounded" />
                    </td>
                    <td className="min-w-24 px-3 py-2">
                        <input type="number" name="liquids" value={editFormData.liquids} onChange={handleInputChange} className="w-full p-2 border rounded" />
                    </td>
                    <td className="min-w-24 px-3 py-2">
                        <input type="number" name="bmr" value={bmr} className="w-full p-2 border rounded" disabled />
                    </td>
                    <td className="min-w-24 px-3 py-2">
                        <input type="number" name="bmi" value={bmi} className="w-full p-2 border rounded" disabled />
                    </td>
                    <td className="min-w-24 px-3 py-2">
                        <input type="number" name="tee" value={tee} className="w-full p-2 border rounded" disabled />
                    </td>
                    <td>
                        <div className='flex'>
                            <button className="mx-2 p-2 border rounded bg-red-500 text-white" onClick={handleCancelClick}><MdCancel size={20} /></button>
                            <button className="mr-2 p-2 border rounded bg-orange-500 text-white" onClick={handleSaveClick}><FaSave size={20} /></button>
                        </div>
                    </td>
                </tr>
                <tr className='text-sm'>
                    <td colspan="8" className='px-3 py-2'>
                        <textarea
                            name="notes"
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                            placeholder='Γράψτε σημειώσεις...'
                            value={editFormData.notes}
                            onChange={handleInputChange}
                            rows={1}
                        />

                    </td>
                </tr>

            </>
        );
    };

    return (
        <>
            <div className="p-4">
                <div className="overflow-x-auto w-full">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Ημ/νια</th>
                                <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Συντελεστης δραστηριοτητας</th>
                                <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Βαρος</th>
                                <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Λιπος</th>
                                <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Υγρα</th>
                                <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">BMR</th>
                                <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">BMI</th>
                                <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">TEE</th>
                                <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {measurements.map(measurement =>
                                editingId === measurement.id ? renderForm() : renderMeasurementRow(measurement)
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='flex justify-end'>
                    <button
                        onClick={handleAddMeasurement}
                        className={`mt-4 p-3 rounded-full shadow-lg ${editingId !== null ? 'bg-gray-400 text-white cursor-not-allowed' : 'bg-orange-500 text-white'}`}
                        disabled={editingId !== null}
                    >
                        <TiPlus size={30} />
                    </button>
                </div>


            </div>
            {showEquivalentsPopup && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[90%]">
                            <h3 className="text-lg bg-orange-500 text-white leading-6 font-medium px-4 py-5">Ισοδύναμα</h3>

                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <div className="mt-2">
                                            <EquivalentsFormPopup initialValues={EquivalentsEdit.equivalents} measurement={EquivalentsEdit} clientdata={clientdata} onSave={handleSaveEquivalents} onCancel={handleCancelEquivalents} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 ">
                                {/* <button
                                        onClick={() => setShowPlanSelectionModal(false)}
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    >
                                        Ακύρωση
                                    </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MeasurementsForm;
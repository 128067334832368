// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AdminWorkspace from './pages/AdminWorkspace';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import { AuthProvider } from './components/Auth/AuthProvider';
import ClientDetails from './pages/ClientDetails';
const App = () => {

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/workspace" element={<ProtectedRoute><AdminWorkspace/></ProtectedRoute>} />
          <Route path="//client/:id" element={<ProtectedRoute><ClientDetails /></ProtectedRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;

import React from 'react';
import ClientEditForm from './ClientEditForm';
import { Link } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import RowEditButton from '../Buttons/RowEditButton';
import RowDeleteButton from '../Buttons/RowDeleteButton';
import { formatDate } from '../../functions/Functions';

const ClientRow = ({ client, isEditing,isDisabled, onEdit, onSave, onCancel, onChange, onDelete }) => {
  return (
    <tr className='text-sm'>
      {isEditing ? (
        <ClientEditForm
          client={client}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
        />
      ) : (
        <>
          <td className="px-3 py-2 whitespace-nowrap">{client.name}</td>
          <td className="px-3 py-2 whitespace-nowrap">{client.status}</td>
          <td className="px-3 py-2 whitespace-nowrap">{client.gender}</td>
          <td className="px-3 py-2 whitespace-nowrap">{formatDate(client.birthday)}</td>
          <td className="px-3 py-2 whitespace-nowrap">{client.height} cm</td>
          <td className="px-3 py-2 whitespace-nowrap">{client.target} kg</td>
          <td className="px-3 py-2 whitespace-nowrap">{client.phone}</td>
          <td className="px-3 py-2 whitespace-nowrap">{client.email}</td>
          <td className="px-3 py-2 whitespace-nowrap">{client.address}</td>
          <td>
            <div className="flex">
              <button className='mx-2 my-2 bg-orange-500 text-white rounded p-2'>
                <Link to={`/client/${client.id}`}>
                  <FaEye size={20} />
                </Link>
              </button>
              <RowEditButton onEdit={onEdit} isDisabled={isDisabled} />
              <RowDeleteButton onDelete={onDelete} id={client.id} isDisabled={isDisabled} />
            </div>

          </td>
        </>
      )}
    </tr>
  );
};

export default ClientRow;

import React from 'react';

const AdminTabs = ({ activeTab, setActiveTab }) => {
  const tabClass = "px-4 py-2 rounded-t-lg text-md font-medium focus:outline-none transition duration-200";
  const activeTabClass = "bg-white text-gray-800 border-b-2 border-orange-500";

  return (
    <div className="flex border-b">
      <button 
        onClick={() => setActiveTab('clients')} 
        className={`${tabClass} ${activeTab === 'clients' ? activeTabClass : 'text-gray-500'}`}
      >
        Διαιτώμενοι
      </button>
      <button 
        onClick={() => setActiveTab('template_plans')} 
        className={`${tabClass} ${activeTab === 'template_plans' ? activeTabClass : 'text-gray-500'}`}
      >
        Πρότυπα Πλάνα 
      </button>
      <button 
        onClick={() => setActiveTab('template_meals')} 
        className={`${tabClass} ${activeTab === 'template_meals' ? activeTabClass : 'text-gray-500'}`}
      >
        Πρότυπα Γεύματα 
      </button>
    </div>
  );
};

export default AdminTabs;

import React, { useState, useEffect } from 'react';
import SaveButton from '../Buttons/SaveButton';
import SuccessMessage from '../Messages/SuccessMessage';
import { formatDate, calculate_bmr_mbi_tee } from '../../functions/Functions';

const EquivalentsForm = ({ initialValues, measurement, clientdata, onSave, onCancel }) => {
  const [formData, setFormData] = useState(initialValues || {});
  const [isEdited, setIsEdited] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [comparisonResults, setComparisonResults] = useState({});

  const { weight, activityFactor } = measurement;

  const bmr_bmi_ib_tee = calculate_bmr_mbi_tee(clientdata, weight, activityFactor);
  let bmr = bmr_bmi_ib_tee[0];
  let bmi = bmr_bmi_ib_tee[1];
  let tee = bmr_bmi_ib_tee[2];

  useEffect(() => {
    setFormData(initialValues || {});
  }, [initialValues]);

  const nutritionalValues = {
    full_milk: { carbohydrates: 12, proteins: 8, fat: 8, calories: 152 },
    semi_milk: { carbohydrates: 12, proteins: 8, fat: 5, calories: 125 },
    zero_milk: { carbohydrates: 12, proteins: 8, fat: 0, calories: 80 },
    fruits: { carbohydrates: 15, proteins: 0, fat: 0, calories: 60 },
    vegetables: { carbohydrates: 5, proteins: 2, fat: 0, calories: 28 },
    bread_cereals: { carbohydrates: 15, proteins: 3, fat: 0, calories: 72 },
    full_meat: { carbohydrates: 0, proteins: 7, fat: 8, calories: 100 },
    semi_meat: { carbohydrates: 0, proteins: 7, fat: 5, calories: 73 },
    zero_meat: { carbohydrates: 0, proteins: 7, fat: 3, calories: 55 },
    fat: { carbohydrates: 0, proteins: 0, fat: 5, calories: 45 }
  };

  const labelMappings = {
    full_milk: "Γάλα πλήρες",
    semi_milk: "Γάλα ημίπαχο",
    zero_milk: "Γάλα 0%",
    fruits: "Φρούτα",
    vegetables: "Λαχανικά",
    bread_cereals: "Ψωμί & Δημητριακά",
    full_meat: "Κρέας πλήρες",
    semi_meat: "Κρέας ημίπαχο",
    zero_meat: "Κρέας 0%",
    fat: "Λίπος"
  };
  // Function to handle form cancellation
  const handleCancel = () => {
    // Reset the form data to initial values
    setFormData(initialValues);
    setIsEdited(false);

    // Call the onCancel prop if provided
    if (onCancel) {
      onCancel();
    }
  };
  const handleInputChange = (e) => {
    if (!isEdited) setIsEdited(true);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: Number(value) || 0 });
  };

  useEffect(() => {
    calculateNutritionalValues();
  }, [formData]);

  const calculateNutritionalValues = () => {
    let newFormData = { ...formData };
    let totals = {
      carbohydrates: 0, proteins: 0, fat: 0, calories: 0
    };


    Object.keys(nutritionalValues).forEach(key => {
      const item = nutritionalValues[key];
      const quantity = newFormData[key] || 0;
      totals.carbohydrates += item.carbohydrates * quantity;
      totals.proteins += item.proteins * quantity;
      totals.fat += item.fat * quantity;
      totals.calories += item.calories * quantity;

      newFormData[`${key}_carbohydrates`] = item.carbohydrates * quantity;
      newFormData[`${key}_proteins`] = item.proteins * quantity;
      newFormData[`${key}_fat`] = item.fat * quantity;
      newFormData[`${key}_calories`] = item.calories * quantity;
    });

    newFormData.total_carbohydrates = totals.carbohydrates;
    newFormData.total_proteins = totals.proteins;
    newFormData.total_fat = totals.fat;
    newFormData.total_calories = totals.calories;

    // Calculate percentages and limit to two decimal places
    if (newFormData.total_calories > 0) {
      newFormData.total_percent_carbohydrates = ((totals.carbohydrates * 4 / newFormData.total_calories) * 100).toFixed(2);
      newFormData.total_percent_proteins = ((totals.proteins * 4 / newFormData.total_calories) * 100).toFixed(2);
      newFormData.total_percent_fat = ((totals.fat * 9 / newFormData.total_calories) * 100).toFixed(2);
    } else {
      newFormData.total_percent_carbohydrates = (0).toFixed(2);
      newFormData.total_percent_proteins = (0).toFixed(2);
      newFormData.total_percent_fat = (0).toFixed(2);
    }

    const totalTargetPercent = (formData.target_carbohydrates_percent || 0) +
      (formData.target_proteins_percent || 0) +
      (formData.target_fat_percent || 0);


    const compareResults = {
      carbohydratesDiff: (totals.carbohydrates - (formData.target_carbohydrates_percent / 100 * formData.targetCalories / 4)).toFixed(0),
      proteinsDiff: (totals.proteins - (formData.target_proteins_percent / 100 * formData.targetCalories / 4)).toFixed(0),
      fatDiff: (totals.fat - (formData.target_fat_percent / 100 * formData.targetCalories / 9)).toFixed(0),
      caloriesDiff: (totals.calories - formData.targetCalories).toFixed(0),
      carbohydratesTarget: ((formData.target_carbohydrates_percent / 100 * formData.targetCalories / 4)).toFixed(0),
      proteinsTarget: ((formData.target_proteins_percent / 100 * formData.targetCalories / 4)).toFixed(0),
      fatTarget: ((formData.target_fat_percent / 100 * formData.targetCalories / 9)).toFixed(0),
      totalTargetPercent,
    };

    setComparisonResults(compareResults);
    setFormData(newFormData);

    // Only update the state if the new data is different
    if (JSON.stringify(newFormData) !== JSON.stringify(formData)) {
      setFormData(newFormData);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSave(formData);
    setIsEdited(false);
    setSuccessMessage("Profile updated successfully!");
    setShowSuccess(true);
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    setTimeout(() => setShowSuccess(false), 5000); // Hide message after 5 seconds
  };

  const renderInputPercent = (name, type = 'number',) => (
    <td className="px-3 py-2 whitespace-nowrap">
      <input
        type='number'
        name={name}
        value={formData[name] || 0}
        onChange={handleInputChange}
        min={0}
        max={100}
        className="shadow appearance-none border rounded w-full py-1 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
      />
    </td>
  );

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <SuccessMessage message={successMessage} isVisible={showSuccess} />
        <table className="min-w-full divide-y divide-gray-200 mb-4">
          <thead className="bg-gray-50">
            <tr>
              <th className="w-1/12 px-2 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Ημ/νια</th>
              <th className="w-1/12 px-2 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Συντελεστης Δ.</th>
              <th className="w-1/12 px-2 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Βαρος</th>
              <th className="w-1/12 px-2 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Λιπος</th>
              <th className="w-1/12 px-2 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Υγρα</th>
              <th className="w-1/12 px-2 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">BMR</th>
              <th className="w-1/12 px-2 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">BMI</th>
              <th className="w-1/12 px-2 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">TEE</th>
            </tr>
          </thead>
          <tbody>
            <tr key={measurement.id} className="text-sm">
              <td className="px-3 py-2 whitespace-nowrap">{formatDate(measurement.date)}</td>
              <td className="px-3 py-2 whitespace-nowrap">{measurement.activityFactor}</td>
              <td className="px-3 py-2 whitespace-nowrap">{measurement.weight}</td>
              <td className="px-3 py-2 whitespace-nowrap">{measurement.fat}</td>
              <td className="px-3 py-2 whitespace-nowrap">{measurement.liquids}</td>
              <td className="px-3 py-2 whitespace-nowrap">{bmr}</td>
              <td className="px-3 py-2 whitespace-nowrap">{bmi}</td>
              <td className="px-3 py-2 whitespace-nowrap">{tee}</td>
            </tr>
          </tbody>
        </table>
        <table className="w-full divide-y divide-gray-200 mb-4">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-2 text-left text-xs w-1/6 font-medium text-orange-500 uppercase tracking-wider">ΣΤΟΧΟΣ ΘΕΡΜΙΔΩΝ (cal)</th>
              <th className="px-3 py-2 text-left text-xs w-1/6 font-medium text-orange-500 uppercase tracking-wider">ΣΤΟΧΟΣ ΥΔΑΤΑΝΘΡΑΚΩΝ (%)</th>
              <th className="px-3 py-2 text-left text-xs w-1/6 font-medium text-orange-500 uppercase tracking-wider">ΣΤΟΧΟΣ ΠΡΩΤΕΪΝΩΝ (%)</th>
              <th className="px-3 py-2 text-left text-xs w-1/6 font-medium text-orange-500 uppercase tracking-wider">ΣΤΟΧΟΣ ΛΙΠΟΥΣ (%)</th>
              <th className="px-3 py-2 text-left text-xs w-1/6 font-medium text-orange-500 uppercase tracking-wider bg-orange-50">ΣΤΟΧΟΣ ΣΥΝΟΛΟ (%)</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <td className="px-3 py-2 whitespace-nowrap">
              <input
                type="number"
                name="targetCalories"
                value={formData.targetCalories || 0}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-1 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
              />
            </td>
            {renderInputPercent('target_carbohydrates_percent')}
            {renderInputPercent('target_proteins_percent')}
            {renderInputPercent('target_fat_percent')}
            <td className={`px-3 py-2 whitespace-nowrap text-white  ${comparisonResults.totalTargetPercent > 99 && comparisonResults.totalTargetPercent < 101 ? 'bg-green-500' : 'bg-red-500'}`}>{comparisonResults.totalTargetPercent} %</td>
          </tbody>
        </table>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-3 text-left text-xs w-1/12 font-medium text-orange-500 uppercase tracking-wider"></th>
              <th className="px-3 py-3 text-left text-xs w-1/12 font-medium text-orange-500 uppercase tracking-wider">Ισοδυναμο</th>
              <th className="px-3 py-3 text-left text-xs w-1/6 font-medium text-orange-500 uppercase tracking-wider">Υδατανθρακες</th>
              <th className="px-3 py-3 text-left text-xs w-1/6 font-medium text-orange-500 uppercase tracking-wider">ΠΡΩΤΕΪΝΕΣ</th>
              <th className="px-3 py-3 text-left text-xs w-1/6 font-medium text-orange-500 uppercase tracking-wider">Λιπος</th>
              <th className="px-3 py-3 text-left text-xs w-1/6 font-medium text-orange-500 uppercase tracking-wider">Θερμιδες</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-sm">
            {/* Rows for each item */}
            {Object.keys(nutritionalValues).map(key => (
              <tr key={key}>
                <td className="px-3 py-2 whitespace-nowrap bg-gray-50">{labelMappings[key]}</td>
                <td className="px-3 py-2 whitespace-nowrap">
                  <input
                    type="number"
                    name={key}
                    value={formData[key] || 0}
                    min={0}
                    onChange={handleInputChange}
                    className="shadow appearance-none border rounded w-full py-1 px-3 bg-white text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                  />
                </td>
                <td className="px-3 py-2 whitespace-nowrap">{formData[`${key}_carbohydrates`] || 0}</td>
                <td className="px-3 py-2 whitespace-nowrap">{formData[`${key}_proteins`] || 0}</td>
                <td className="px-3 py-2 whitespace-nowrap">{formData[`${key}_fat`] || 0}</td>
                <td className="px-3 py-2 whitespace-nowrap">{formData[`${key}_calories`] || 0}</td>
              </tr>
            ))}
            <tr key='total_gr' className='bg-orange-50'>
              <td className="px-3 py-2 whitespace-nowrap">Σύνολο gr</td>
              <td className="px-3 py-2 whitespace-nowrap"></td>
              {comparisonResults && (
                <>
                  <td className="px-3 py-2 whitespace-nowrap">
                    {formData['total_carbohydrates'] || 0}
                    <span className={`${comparisonResults.carbohydratesDiff <= 4 && comparisonResults.carbohydratesDiff >= -4 ? 'bg-green-500' : 'bg-red-500'} ml-3 px-1 py-1 text-white rounded-md text-xm`}>{comparisonResults.carbohydratesDiff} </span>
                    <span className='ml-3 px-1 py-1 border border-gray-700 rounded-md text-xm'>{comparisonResults.carbohydratesTarget}</span>
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap">
                    {formData['total_proteins'] || 0}
                    <span className={`${comparisonResults.proteinsDiff <= 4 && comparisonResults.proteinsDiff >= -4 ? 'bg-green-500' : 'bg-red-500'} ml-3 px-1 py-1 text-white rounded-md text-xm`}>{comparisonResults.proteinsDiff} </span>
                    <span className='ml-3 px-1 py-1 border border-gray-700 rounded-md text-sm'>{comparisonResults.proteinsTarget}</span>
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap">
                    {formData['total_fat'] || 0}
                    <span className={`${comparisonResults.fatDiff <= 7 && comparisonResults.fatDiff >= -7 ? 'bg-green-500' : 'bg-red-500'} ml-3 px-1 py-1 text-white rounded-md text-sm`}>{comparisonResults.fatDiff} </span>
                    <span className='ml-3 px-1 py-1 border border-gray-700 text-xm rounded-md'>{comparisonResults.fatTarget}</span>
                  </td>
                  <td className="px-3 py-2 whitespace-nowrap"></td>
                </>
              )}
            </tr>
            <tr key='total_cal' className='bg-orange-50'>
              <td className="px-3 py-2 whitespace-nowrap">Σύνολο cal</td>
              <td className="px-3 py-2 whitespace-nowrap"></td>
              <td className="px-3 py-2 whitespace-nowrap">{formData['total_carbohydrates'] * 4 || 0}</td>
              <td className="px-3 py-2 whitespace-nowrap">{formData['total_proteins'] * 4 || 0}</td>
              <td className="px-3 py-2 whitespace-nowrap">{formData['total_fat'] * 9 || 0}</td>
              <td className="px-3 py-2 whitespace-nowrap">{

                formData['total_calories'] || 0}
                <span className={`${comparisonResults.caloriesDiff < 100 && comparisonResults.caloriesDiff > -100 ? 'bg-green-500' : 'bg-red-500'} ml-3 px-1 py-1 text-white rounded-md text-xm`}>{comparisonResults.caloriesDiff} </span>
                <span className='ml-3 px-1 py-1 border border-gray-700 rounded-md text-xm'>{formData.targetCalories}</span>

              </td>
            </tr>
            <tr key='total_percent' className='bg-orange-50'>
              <td className="px-3 py-2 whitespace-nowrap">Σύνολο %</td>
              <td className="px-3 py-2 whitespace-nowrap"></td>
              <td className="px-3 py-2 whitespace-nowrap">{formData.total_percent_carbohydrates || '0.00'}</td>
              <td className="px-3 py-2 whitespace-nowrap">{formData.total_percent_proteins || '0.00'}</td>
              <td className="px-3 py-2 whitespace-nowrap">{formData.total_percent_fat || '0.00'}</td>
              <td className="px-3 py-2 whitespace-nowrap"></td>
            </tr>
          </tbody>
        </table>

        <div className="flex justify-end items-end space-x-2">
          <button
            onClick={handleCancel}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none">
            Cancel
          </button>
          <SaveButton onClick={handleSubmit} visibility={true} />

        </div>
      </form>
    </div>
  );
};

export default EquivalentsForm;

import React, { useState, useEffect } from 'react';
import Header from '../components/Header/Header';
import ClientRow from '../components/ClientsTable/ClientRow';
import { TiPlus } from "react-icons/ti";
import { fetchClients, updateClient, deleteClient, addClient } from '../firestore/Firestore';
import Spinner from '../components/Spinner/Spinner';
import AdminTabs from './AdminTabs';
import PlansForm from '../components/ClientsDetails/PlansForm';
import MealsForm from '../components/MealsForm';
import Message from '../components/Message';

const AdminWorkspace = () => {
  const [clients, setClients] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState('clients');  // Default active tab
  const [currentMessage, setCurrentMessage] = useState(null);
  const [messageType, setMessageType] = useState('');

  const showMessage = (message, type) => {
    setCurrentMessage(message);
    setMessageType(type);
  };

  const clearMessage = () => {
    setCurrentMessage(null);
  };
  
  useEffect(() => {
    const fetchData = async () => {

      setLoading(true);
      try {
        const clientsList = await fetchClients();
        setClients(clientsList);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const handleEditClick = (client) => {
    setEditingId(client.id);
    setIsDisabled(true)
    setEditFormData(client);
  };

  const handleCancelClick = () => {
    if (editingId === 'new') {
      // Remove the new entry that hasn't been saved
      setClients(clients.filter(client => client.id !== 'new'));
    }
    setEditingId(null);
    setIsDisabled(false);
    setEditFormData({});
  };

  const handleSaveClick = async () => {
    try {
      if (editingId === 'new') {
        const newId = await addClient(editFormData);
        setClients([...clients.filter(client => client.id !== 'new'), { ...editFormData, id: newId }]);
        showMessage(<span>
         Ο διαιτώμενος {editFormData.name} προστέθηκε.
      </span>, 'success');
      } else {
        await updateClient(editingId, editFormData);
        setClients(clients.map(client => client.id === editingId ? { ...editFormData, id: editingId } : client));
        showMessage(<span>
          Τα στοιχεία του διαιτώμενου {editFormData.name} ενημερώθηκαν.
      </span>, 'success');
      }
      setEditingId(null);
      setIsDisabled(false);
      setEditFormData({});
    } catch (error) {
      console.error('Error saving client:', error);
    }
  };

  const handleDelete = async (clientId) => {
    try {
      await deleteClient(clientId);
      setClients(clients.filter(client => client.id !== clientId));
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const handleEditFormChange = (e) => {
    setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
  };

  const handleAddClient = () => {
    const newClient = {
      id: 'new',
      name: '',
      status: '',
      gender: '',
      birthday: '',
      height: '',
      target: '',
      phone: '',
      email: '',
      address: '',
    };

    setClients([...clients, newClient]);
    setEditingId('new');
    setIsDisabled(true);
  };

  return (
    <div className="relative min-h-screen bg-gray-50">
      <Header />

        <Message
                message={currentMessage}
                type={messageType}
                onDismiss={clearMessage}
            />
      <main className="p-4 max-w-8xl p-2 mx-auto">
        <AdminTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        <div className="bg-white mt-4">
          {activeTab === 'clients' && (
            <>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <div className="overflow-x-auto w-full p-5 bg-white rounded-lg shadow mb-6">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="w-2/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Name</th>
                          <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Status</th>
                          <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Gender</th>
                          <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Birthday</th>
                          <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Height</th>
                          <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Target W.</th>
                          <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Phone</th>
                          <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Email</th>
                          <th className="w-1/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider">Address</th>
                          <th className="w-2/12 px-3 py-3 text-left text-xs font-medium text-orange-500 uppercase tracking-wider"></th>
                        </tr>
                      </thead>

                      <tbody className="bg-white divide-y divide-gray-200">
                        {clients.map(client => (
                          <ClientRow
                            key={client.id}
                            client={editingId === client.id ? editFormData : client}
                            isEditing={editingId === client.id}
                            isDisabled={isDisabled}
                            onEdit={() => handleEditClick(client)}
                            onSave={handleSaveClick}
                            onCancel={handleCancelClick}
                            onChange={handleEditFormChange}
                            onDelete={handleDelete}
                          />
                        ))}
                      </tbody>


                    </table>
                  </div>
                  <button
                    className={`fixed bottom-4 right-4 p-3 rounded-full shadow-lg ${isDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-orange-500'} text-white`}
                    onClick={handleAddClient}
                    disabled={isDisabled}
                  >
                    <TiPlus size={40} />
                  </button>
                </>
              )}
            </>
          )}

          {activeTab === 'template_plans' && <PlansForm collectionPath={`TemplatePlans`} type={'templates'} />}
          {activeTab === 'template_meals' && <MealsForm/>}

        </div>
      </main>

    </div>
  );
};

export default AdminWorkspace;

// HomePage.js
import React, { useState } from 'react';
import Header from '../components/Header/Header';
import backgroundImage from '../assets/background-image.png'; // Import the background image
import backgroundImage2 from '../assets/background-image2.jpg'; // Import the background image
import backgroundImage3 from '../assets/scale2.jpg'; // Import the background image
import { FaArrowAltCircleDown } from "react-icons/fa";
import { FaNutritionix } from "react-icons/fa";
import { GiAchievement } from "react-icons/gi";
import { FaRoadCircleCheck } from "react-icons/fa6";
import { FaCarrot } from "react-icons/fa6";
import { GiPartyPopper } from "react-icons/gi";
import { FaCalendarAlt } from "react-icons/fa";
import Footer from '../components/Footer/Footer';
import { FaChildren } from "react-icons/fa6";
import { FaClinicMedical } from "react-icons/fa";
import { MdOutlineSyncProblem } from "react-icons/md";
import { GiWeightScale } from "react-icons/gi";
import { GiBodySwapping } from "react-icons/gi";
import { IoWoman } from "react-icons/io5";
import { FaBabyCarriage } from "react-icons/fa";
import { FaPersonRunning } from "react-icons/fa6";

const InfoBox = ({ Icon, text }) => {
  return (
    <div className="flex flex-col items-center p-6 text-center border rounded shadow-md mt-8 border border-orange-500 rounded-xl shadow-xl">
      <div className='border border-orange-500 rounded-xl shadow-xl mb-4' style={{ marginTop: "-50px" }}>
        <Icon className="text-3xl bg-orange-500 text-white rounded-xl p-4 icon-outside border-4 border-white" size={100} />
      </div>
      <p>{text}</p>
    </div>
  );
};

const ServiceBox = ({ Icon, title }) => {
  return (
    <div className="flex flex-col items-center p-6 text-center border rounded shadow-md mt-8 border border-orange-500 rounded-xl shadow-xl bg-white bg-opacity-60">
      <div className='border border-orange-500 rounded-xl shadow-xl mb-4'>
        <Icon className="text-orange-500 rounded-xl p-4 icon-outside border-4 border-white" size={70} />
      </div>
      <h3 className="text-lg font-semibold text-gray-600">{title}</h3>
    </div>
  );
};

const HomePage = () => {

  const [userInput, setUserInput] = useState({ height: '', age: '' });
  const [result, setResult] = useState('');

  // Handle input change
  const handleInputChange = (e) => {
    setUserInput({ ...userInput, [e.target.name]: e.target.value });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const heightInMeters = userInput.height / 100;
    const weightLower = 18.5 * heightInMeters * heightInMeters;
    const weightUpper = 24.9 * heightInMeters * heightInMeters;
    const calculatedResult = `${weightLower.toFixed(2)} kg - ${weightUpper.toFixed(2)} kg`;
    setResult(calculatedResult);
  };


  const handleClick = (e) => {
    e.preventDefault();
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      window.scrollTo({
        top: mainContent.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <div className="min-h-60 md:min-h-screen relative">
        <img src={backgroundImage} alt="Background" className="absolute inset-0 w-full h-60 md:h-full object-cover" />
        <Header />
        <main className="p-4 relative">
        </main>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 md:bottom-5">
          <a href='/#main-content' onClick={handleClick}>
            <FaArrowAltCircleDown className="animated-arrow opacity-70 hover:opacity-100 text-4xl md:text-6xl" color='#ff8436' />
          </a>
        </div>
      </div>
      <div id='main-content' className="p-4 my-12 flex justify-center">
        <div className='max-w-6xl'>
          <h2 className="text-3xl font-medium text-orange-500 mb-4 text-center">Λίγα λόγια απ΄εμένα</h2>
          <h2 className="text-left text-2xl font-bold mt-5 mb-1 text-orange-500">Κλειώ Ξυνορόδου</h2>
          <h2 className="text-left text-xl font-bold text-gray-700 mb-5">Διαιτολόγος – Διατροφολόγος, PhD</h2>
          <p>

            Καλωσορίσατε στη προσωπική μου σελίδα! Είμαι εδώ για να σας ενθαρρύνω να αγκαλιάσετε την υγεία σας και να φροντίσετε τον εαυτό σας με αγάπη και φροντίδα. Η αποστολή μου είναι να σας δείξω τον δρόμο προς μια ισορροπημένη και γεμάτη ζωή διατροφή.<br></br>

            Η διατροφή αποτελεί έναν κρίσιμο παράγοντα στην καθημερινότητά μας, επηρεάζοντας από την ενέργειά μας μέχρι τη διάθεσή μας. Αλλά η κατανόηση της σωστής διατροφής δεν είναι πάντα εύκολη, και δεν βρίσκεται στις αναζητήσεις του Google ή στα κοινωνικά δίκτυα.<br></br>

            Κάθε άνθρωπος είναι μοναδικός, με διαφορετικές ανάγκες και προτιμήσεις. Γι' αυτό, η προσέγγιση μου στη διατροφή είναι προσωπική και εξατομικευμένη. Τι μπορώ να κάνω για εσάς;
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 my-8">
            {/* Example Box */}
            <InfoBox Icon={FaNutritionix} text="Θα σας βοηθήσω να ανακαλύψετε τη διατροφή που ταιριάζει ακριβώς σε εσάς." />
            <InfoBox Icon={GiAchievement} text="Θα σας παρέχω την ενθάρρυνση και το κίνητρο για να πετύχετε τους στόχους σας." />
            <InfoBox Icon={FaRoadCircleCheck} text="Θα είμαι δίπλα σας σε κάθε βήμα της διαδρομής σας, ακόμη και όταν νιώθετε πως αποτυγχάνετε." />
            <InfoBox Icon={FaCalendarAlt} text="Θα σας προσφέρω λύσεις και στρατηγικές για να διαχειριστείτε τυχόν αποκλίσεις από το σχέδιο σας." />
            <InfoBox Icon={GiPartyPopper} text="Θα γιορτάσω μαζί σας κάθε επιτυχία και πρόοδο που θα καταγράψετε." />
            <InfoBox Icon={FaCarrot} text="Θα σας παρέχω συμβουλές και γνώσεις για να τρέφεστε υγιεινά και ισορροπημένα." />
          </div>
          <p>
            Ανυπομονώ να σας συναντήσω και να ξεκινήσουμε μαζί αυτό το ταξίδι προς μια υγιεινότερη ζωή!
          </p>
        </div>
      </div>

      <div id='services' className="relative px-4 py-16 flex justify-center">
        <img src={backgroundImage2} alt="Background" className="absolute inset-0 w-full h-60 md:h-full object-cover" />
        <div className="absolute inset-0 bg-white opacity-60"></div> {/* Overlay with reduced opacity */}
        <div className="relative z-10">
          <div className='max-w-6xl'>
            <h2 className="text-3xl font-medium text-orange-500 mb-4 text-center">Υπηρεσίες</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-8">
              <ServiceBox Icon={FaChildren} title="Διατροφή για Παιδιά & Εφήβους" />
              <ServiceBox Icon={FaClinicMedical} title="Κλινική Διατροφή" />
              <ServiceBox Icon={MdOutlineSyncProblem} title="Διατροφικές Διαταραχές" />
              <ServiceBox Icon={GiWeightScale} title="Διαχείριση Βάρους" />
              <ServiceBox Icon={GiBodySwapping} title="Ανάλυση Σύστασης Σώματος" />
              <ServiceBox Icon={FaBabyCarriage} title="Διατροφή για Εγκυμοσύνη & Θηλασμό" />
              <ServiceBox Icon={IoWoman} title="Διατροφή για Εμμηνόπαυση" />
              <ServiceBox Icon={FaPersonRunning} title="Διατροφή για Αθλητές ή Αθλούμενους" />
            </div>
          </div>
        </div>
      </div>

      <div id='calculator-section' className="flex justify-center items-center py-16">
        <div className='max-w-6xl'>
          <h2 className="text-3xl font-medium text-orange-500 mb-4 text-center">Υπολογίστε το Ιδανικό Βάρος σας</h2>
          <div className='flex flex-col md:flex-row justify-center items-center'>
            <div className='w-full md:w-1/3'>
              <form onSubmit={handleSubmit} className="space-y-4 px-20 pt-8 py-8 bg-orange-500 bg-opacity-15 rounded-md mx-4">
                <div>
                  <label htmlFor="height" className="block text-sm font-medium text-gray-700">Ύψος (cm)</label>
                  <input type="number" name="height" value={userInput.height} placeholder='ύψος...' onChange={handleInputChange} className="mt-1 block pl-2 py-1 focus:outline-none w-full border border-gray-300 rounded-md shadow-sm" />
                </div>
                <div>
                  <label htmlFor="age" className="block text-sm font-medium text-gray-700">Ηλικία (έτη)</label>
                  <input type="number" name="age" value={userInput.age} placeholder='ηλικία...' onChange={handleInputChange} className="mt-1 pl-2 py-1 focus:outline-none block w-full border border-gray-300 rounded-md shadow-sm" />
                </div>
                <button type="submit" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 w-full">Υπολογισμός</button>
                {result && <div className="mt-4 text-md text-gray-700">Ιδανικό εύρος βάρους: {result}</div>}
              </form>

            </div>
            <div className='w-full md:w-2/3 px-10 md:px-20'>
              <img src={backgroundImage3} alt="Background" className="w-full h-auto object-cover" />
            </div>
          </div>
        </div>
      </div>


      <Footer />

    </>
  );
};

export default HomePage;

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

export const calculate_bmr_mbi_tee = (clientData, weight, activityFactor) => {

    let bmr = null;
    let tee = null;
    let bmi = (weight / (clientData.height * clientData.height)) * 10000;;
    const gender = clientData.gender;
    const age = calculateAge(clientData.birthday);

    if (gender === 'Male') {
        if (age < 3){
            bmr = 59.512 * weight - 30.4;
        }else if (age<10) {
            bmr = 22.706 * weight + 504.3;
        }else if (age<18) {
            bmr = 17.686 * weight + 658.2;
        }else if (age<30) {
            bmr = 15.057 * weight + 692.2;
        }else if (age<60) {
            console.log('hiiiii')
            bmr = 11.472 * weight + 873.1;
        }else {
            bmr = 11.711 * weight + 587.7;
        }
    }
    else if (gender === 'Female') {
        if (age < 3){
            bmr = 58.317 * weight - 31.1;
        }else if (age<10) {
            bmr = 20.315 * weight + 485.9;
        }else if (age<18) {
            bmr = 13.384 * weight + 692.6;
        }else if (age<30) {
            bmr = 14.818 * weight + 486.6;
        }else if (age<60) {
            bmr = 8.126 * weight + 845.6;
        }else {
            bmr = 9.082 * weight + 658.5;
        }
    }

    tee = bmr * activityFactor;

    tee = parseFloat(tee.toFixed(2));
    bmr = parseFloat(bmr.toFixed(2));
    bmi = parseFloat(bmi.toFixed(2));

    return [bmr, bmi , tee];
}

export const calculate_ib = (clientData) => {

    let ib = null;

    if (clientData.gender === 'Male') {
        ib = (clientData.height * clientData.height) * 22.7 / 10000;
    }
    else if (clientData.gender === 'Female') {
        ib = (clientData.height * clientData.height) * 22.4 / 10000;
    }

    ib = parseFloat(ib.toFixed(2));

    return ib;
}
import React, { useState, useEffect, useRef } from 'react';
import { firestore } from '../../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { FaPlus, FaTrash, FaCopy, FaSave, FaPrint, FaChevronDown, FaChevronRight } from "react-icons/fa";
import { GiMeal } from "react-icons/gi";
import { MdCancel } from "react-icons/md";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import logo from '../../assets/logo.png';
import html2pdf from "html2pdf.js";
import { useReactToPrint } from 'react-to-print';

import 'react-quill/dist/quill.snow.css'; // import styles
import { Highlight } from 'quill-highlight';
import { FaChevronLeft } from "react-icons/fa";

// Register the module
Quill.register('modules/highlight', Highlight);

const Message = ({ message, type, onDismiss }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onDismiss();
        }, 5000);

        return () => clearTimeout(timer);
    }, [message, onDismiss]);

    if (!message) return null;

    return (
        <div className={`fixed top-5 right-5 p-4 border z-40 rounded-lg shadow-md transition-opacity duration-300 text-sm ${type === 'success' ? 'bg-green-100 border-green-400 text-green-700' : 'bg-red-100 border-red-400 text-red-700'
            }`}>
            {message}
        </div>
    );
};


const PlansForm = ({ collectionPath, type }) => {
    const [plan, setPlan] = useState({ plans: [], mealNames: {}, notes: {} });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isNewPlan, setIsNewPlan] = useState(false);
    const [planName, setPlanName] = useState('');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [showPlanSelectionModal, setShowPlanSelectionModal] = useState(false);
    const [originalPlan, setOriginalPlan] = useState(null);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [messageType, setMessageType] = useState('');
    const [templatePlans, setTemplatePlans] = useState([]);
    const [clientsPlansMap, setClientsPlansMap] = useState({});

    const [selectedTemplateType, setSelectedTemplateType] = useState('blank');
    const [selectedClientId, setSelectedClientId] = useState('');

    const [groupedTemplates, setGroupedTemplates] = useState({});

    const [openSection, setOpenSection] = useState(null);

    const [isTemplatesCollapsed, setIsTemplatesCollapsed] = useState(true);

    // Toggle function for the template meals column
    const toggleTemplates = () => {
        setIsTemplatesCollapsed(!isTemplatesCollapsed);
    };

    const mealTitles = {
        breakfast: "Πρωινό",
        lunch: "Μεσημεριανό",
        snack: "Σνακ"
    };

    const handleAccordionClick = (section) => {
        if (openSection === section) {
            setOpenSection(null);
        } else {
            setOpenSection(section);
        }
    };


    useEffect(() => {
        const fetchMealTemplates = async () => {
            try {
                const templatesRef = collection(firestore, "meals");
                const querySnapshot = await getDocs(templatesRef);
                let templates = [];
                querySnapshot.forEach((doc) => {
                    templates.push({ id: doc.id, ...doc.data() });
                });
                // Group templates by type
                const grouped = templates.reduce((groups, template) => {
                    (groups[template.type] = groups[template.type] || []).push(template);
                    return groups;
                }, {});
                setGroupedTemplates(grouped);

            } catch (error) {
                console.error("Error fetching meal templates:", error);
            }
        };

        fetchMealTemplates();
    }, []);


    const copyToClipboard = htmlContent => {
        // Create a temporary element to hold the HTML content
        // Create a new contenteditable element
        const element = document.createElement('div');
        element.contentEditable = 'true';
        element.innerHTML = htmlContent;
        document.body.appendChild(element);

        // Select the content
        const range = document.createRange();
        range.selectNodeContents(element);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        // Copy the selection to the clipboard
        document.execCommand('copy');

        // Clean up
        selection.removeAllRanges();
        document.body.removeChild(element);
    };

    // Function to adjust font size of multiple elements
    function adjustFontSizeForElements(elements, minFontSize, maxFontSize) {
        let fontSize = maxFontSize;

        while (fontSize >= minFontSize) {
            // Apply the font size to all elements
            for (const element of elements) {
                element.style.fontSize = `${fontSize}px`;
            }

            // Check the height limit after adjusting all elements
            if (contentHeightExceedsLimit()) {
                fontSize--; // Reduce font size for the next iteration
            } else {
                break; // Exit the loop if within the height limit
            }
        }
    }


    // Function to check if total content height exceeds 210mm
    function contentHeightExceedsLimit() {
        const container1 = document.getElementById('part-header');
        const container2 = document.getElementById('plan-table');
        const container3 = document.getElementById('notes-table');

        if (!container1 || !container2 || !container3) {
            return false; // One or more elements are not rendered yet
        }

        const maxHeightInPx = 200 * 3.7795275591; // Convert 210mm to pixels
        let total = container1.offsetHeight + container2.offsetHeight + container3.offsetHeight;
        return total > maxHeightInPx;
    }

    // Function to call on component mount and window resize
    function resizeFont() {
        const planContents = document.querySelectorAll('.plan-content');
        // adjustFontSizeForElements(planHeaders, 12, 16);
        adjustFontSizeForElements(planContents, 3, 12);

    }

    // Call resizeFont when the component mounts
    useEffect(() => {
        const resize = () => {
            // Make sure elements are available before trying to adjust font sizes
            if (document.getElementById('part-header') && document.getElementById('plan-table') && document.getElementById('notes-table')) {
                resizeFont();
            }
        }

        window.onload = resize;
        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []); // Empty dependency array ensures this effect runs only once after the initial render



    useEffect(() => {
        const fetchTemplatePlans = async () => {
            // Fetch plans from the current client

            let templatePlans = await getPlans(`TemplatePlans`);
            setTemplatePlans(templatePlans);
            console.log('templates', templatePlans);

            const clientsTemplates = await fetchPlansFromClients();
            setClientsPlansMap(clientsTemplates);

        };

        fetchTemplatePlans();
    }, [collectionPath, type, setPlan, plan]);

    const fetchPlansFromClients = async () => {
        let clientPlansMap = {};

        try {
            // Fetch all client IDs
            const clientsRef = collection(firestore, "Clients");
            const querySnapshot = await getDocs(clientsRef);

            for (const doc of querySnapshot.docs) {
                const clientId = doc.id;
                const clientName = doc.data().name; // Assuming 'name' is the field for the client's name
                const clientPlans = await getPlans(`Clients/${clientId}/Plans`);

                // Map client ID to their plans
                clientPlansMap[clientName] = clientPlans;
            }
        } catch (error) {
            console.error("Error fetching plans from clients:", error);
        }

        return clientPlansMap;
    };


    const getPlans = async (collectionPath) => {
        console.log('collectionPath',collectionPath);
        try {
            const plansRef = collection(firestore, collectionPath);
            const querySnapshot = await getDocs(plansRef);

            let fetchedPlans = [];
            querySnapshot.forEach(doc => {
                const data = doc.data();
                console.log('->', data);
                // Ensure meal names are in default order
                const sortedMealNames = Object.keys(data.mealNames || {}).sort().reduce((acc, key) => {
                    acc[key] = data.mealNames[key];
                    return acc;
                }, {});

                fetchedPlans.push({ id: doc.id, ...data, mealNames: sortedMealNames, notes: data.notes || defaultNotes() });
            });

            console.log('---->', fetchedPlans);
            return fetchedPlans;

        } catch (err) {
            setError('Failed to fetch plans');
        }
    };

    const showMessage = (message, type) => {
        setCurrentMessage(message);
        setMessageType(type);
    };

    const clearMessage = () => {
        setCurrentMessage(null);
    };

    PlansForm.modules = {
        toolbar: [
            ['bold', 'italic', 'underline', { 'background': [] }],

        ]
    };

    PlansForm.formats = [
        'bold', 'italic', 'underline', 'background',
    ];

    const defaultMealNames = {
        meal1: 'Breakfast',
        meal2: 'Snack 1',
        meal3: 'Lunch',
        meal4: 'Snack 2',
        meal5: 'Dinner',
    };

    const noteOrder = ['note1', 'note2', 'note3', 'note4', 'note5', 'note6'];
    const defaultNotes = () => ({
        note1: 'Πρόσληψη νερού: 7-9 ποτήρια',
        note2: '* μπανάνα: το 1 φρούτο ισοδυναμεί με μισή μπανάνα ',
        note3: 'Ατομική Σαλάτα: ωμά ή βραστά λαχανικά με 1 κουτ. σούπας ελαιόλαδο ',
        note4: 'Η μερίδα φαγητό να σερβίρεται κατά προτίμηση σε ρηχό πιάτο (εκτός σούπας)',
        note5: 'Ανάμεσα στα γεύματα να μεσολαβούν 3-4 ώρες τουλάχιτον',
        note6: 'Σε περίπτωση επιθυμίας κάποιου γλυκού σνακ κατά προτίμηση κατανάλωση μετά από ένα γεύμα, την ώρα του γεύματος και όχι κάποια άλλη στιγμή μόνο του ! 1 γλυκό/εβδομάδα'
    });


    const sortPlansByDate = (plans) => {
        return plans.sort((a, b) => b.dateSaved.localeCompare(a.dateSaved));
    };


    const defaultPlanStructure = () => {
        const days = ['1η Ημέρα', '2η Ημέρα', '3η Ημέρα', '4η Ημέρα', '5η Ημέρα', '6η Ημέρα', '7η Ημέρα'];
        return days.map(day => ({
            day,
            meals: Object.keys(defaultMealNames).reduce((acc, mealType) => {
                acc[mealType] = '<p><br></p>';
                return acc;
            },
                {}),
        }));
    };

    const handlePublishChange = (planId, newStatus) => {
        if (!hasUnsavedChanges) {
            // Store the original plan when a new meal type is added
            setOriginalPlan({ ...plan });
        }
        const updatedPlans = allPlans.map(p => {
            if (p.id === planId) {
                return { ...p, isPublished: newStatus };
            }
            return p;
        });
        setAllPlans(updatedPlans);
        // Also update the selected plan if it's currently being edited
        if (plan.id === planId) {
            setPlan(prev => ({ ...prev, isPublished: newStatus }));
        }
        console.log('change it');
        setHasUnsavedChanges(true);
    };

    const duplicatePlan = async () => {
        const currentDate = new Date().toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
        const duplicatedPlanName = `${plan.name}_duplicate_${currentDate}`;
        const now = new Date();
        const dateSaved = now.toISOString().split('T')[0] + ' ' +
            now.toTimeString().split(' ')[0];

        const newPlanData = {
            ...plan,
            name: duplicatedPlanName,
            dateSaved: dateSaved,
            isPublished: 'false'
        };

        try {
            const plansRef = collection(firestore, collectionPath);
            const docRef = await addDoc(plansRef, newPlanData);

            // Add the new plan to the list and select it
            const newPlanWithId = { ...newPlanData, id: docRef.id };
            await updateDoc(docRef, newPlanWithId);

            // setAllPlans([...allPlans, newPlanWithId]);
            setAllPlans(sortPlansByDate([...allPlans, newPlanWithId]));

            setPlan(newPlanWithId);
            setPlanName(duplicatedPlanName);
            setIsNewPlan(false); // Set as existing plan
        } catch (err) {
            console.error("Error duplicating plan:", err);
            setError('Failed to duplicate plan');
        }
    };


    // Function to add a new meal type
    const addMealType = () => {
        if (!hasUnsavedChanges) {
            // Store the original plan when a new meal type is added
            setOriginalPlan({ ...plan });
        }
        // Extract numbers from meal type keys and find the highest
        const mealNumbers = Object.keys(plan.mealNames)
            .map(key => parseInt(key.replace('meal', ''), 10))
            .filter(num => !isNaN(num)); // Filter out NaN values

        const highestNumber = mealNumbers.length > 0 ? Math.max(...mealNumbers) : 0;
        const newMealNumber = highestNumber + 1; // Increment the highest number by 1

        const newMealType = `meal${newMealNumber}`;
        const newMealName = `New Meal ${newMealNumber}`;

        setPlan(prevPlan => ({
            ...prevPlan,
            mealNames: { ...prevPlan.mealNames, [newMealType]: newMealName },
            plans: prevPlan.plans.map(dayPlan => ({
                ...dayPlan,
                meals: { ...dayPlan.meals, [newMealType]: '' }
            }))
        }));
        console.log('change it');

        setHasUnsavedChanges(true);
    };

    // Function to delete a meal type
    const deleteMealType = mealType => {
        if (!hasUnsavedChanges) {
            // Store the original plan when a new meal type is added
            setOriginalPlan({ ...plan });
        }
        const { [mealType]: _, ...newMealNames } = plan.mealNames;
        setPlan(prevPlan => ({
            ...prevPlan,
            mealNames: newMealNames,
            plans: prevPlan.plans.map(dayPlan => {
                const { [mealType]: _, ...newMeals } = dayPlan.meals;
                return { ...dayPlan, meals: newMeals };
            })
        }));
        console.log('change it');

        setHasUnsavedChanges(true);

    };

    const [allPlans, setAllPlans] = useState([]);

    const handleNoteChange = (noteKey, content) => {
        if (!hasUnsavedChanges) {
            console.log('store oroginal', plan);
            // Store the original plan when first change is made
            setOriginalPlan({ ...plan });
        }
        setPlan(prevPlan => ({
            ...prevPlan,
            notes: {
                ...prevPlan.notes,
                [noteKey]: content
            }
        }));
        console.log('change it note');

        setHasUnsavedChanges(true);
    };

    useEffect(() => {
        const fetchPlans = async () => {
            setLoading(true);
            try {
                const plansRef = collection(firestore, collectionPath);
                const querySnapshot = await getDocs(plansRef);

                let fetchedPlans = [];
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    // Ensure meal names are in default order
                    const sortedMealNames = Object.keys(data.mealNames || {}).sort().reduce((acc, key) => {
                        acc[key] = data.mealNames[key];
                        return acc;
                    }, {});

                    fetchedPlans.push({ id: doc.id, ...data, mealNames: sortedMealNames, notes: data.notes || defaultNotes() });
                });

                setAllPlans(sortPlansByDate(fetchedPlans));
                if (fetchedPlans.length > 0) {
                    const latestPlan = fetchedPlans.sort((a, b) => b.dateSaved.localeCompare(a.dateSaved))[0];
                    setPlan(latestPlan);
                    setPlanName(latestPlan.name);
                } else {
                    setPlan({ plans: defaultPlanStructure(), mealNames: defaultMealNames });
                }


            } catch (err) {
                setError('Failed to fetch plans');
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, [collectionPath]);

    const savePlan = async () => {
        try {
            const plansRef = collection(firestore, collectionPath);
            const nameToSave = planName || `Plan_${new Date().toISOString().split('T')[0]}`;
            const now = new Date();
            const dateSaved = now.toISOString().split('T')[0] + ' ' +
                now.toTimeString().split(' ')[0];

            const newPlanData = {
                plans: plan.plans,
                name: nameToSave,
                dateSaved: dateSaved,
                mealNames: plan.mealNames,
                isPublished: plan.isPublished,
                notes: plan.notes
            };

            if (isNewPlan) {
                const docRef = await addDoc(plansRef, newPlanData);
                const savedPlanWithId = { ...newPlanData, id: docRef.id };
                await updateDoc(docRef, savedPlanWithId);

                // Replace the draft plan with the newly saved plan and update the selected plan
                const updatedPlans = allPlans.map(p => p.id.startsWith('temp_') ? savedPlanWithId : p);
                setAllPlans(sortPlansByDate(updatedPlans));
                setPlan(savedPlanWithId);  // Set the newly saved plan as the selected plan
                setPlanName(savedPlanWithId.name);

                setIsNewPlan(false);
            } else {
                // Updating an existing plan
                const planDocRef = doc(firestore, collectionPath, plan.id);
                await updateDoc(planDocRef, newPlanData);
                const updatedPlans = allPlans.map(p => p.id === plan.id ? { ...newPlanData, id: plan.id } : p);
                setAllPlans(sortPlansByDate(updatedPlans));
                setPlan({ ...newPlanData, id: plan.id });  // Update the selected plan
                setPlanName(nameToSave);
            }
            setOriginalPlan(null); // Clear the original plan on successful save

            showMessage(<span>
                Το πλάνο <strong>{nameToSave}</strong> αποθηκεύτηκε!
            </span>, 'success');



        } catch (err) {
            setError('Failed to save plan');
        }
        setHasUnsavedChanges(false);

    };


    const handleMealChange = (dayIndex, mealType, content) => {

        if (!hasUnsavedChanges) {
            setOriginalPlan({ ...plan });
        }

        setPlan(prevPlan => ({
            ...prevPlan,
            plans: prevPlan.plans.map((dayPlan, index) =>
                index === dayIndex
                    ? {
                        ...dayPlan,
                        meals: { ...dayPlan.meals, [mealType]: content }
                    }
                    : dayPlan
            )
        }));

        setHasUnsavedChanges(true);
    };



    const handleMealNameChange = (mealType, newName) => {
        if (!hasUnsavedChanges) {
            // Store the original plan when first change is made
            setOriginalPlan({ ...plan });
        }

        setPlan(prevPlan => ({
            ...prevPlan,
            mealNames: { ...prevPlan.mealNames, [mealType]: newName }
        }));

        setHasUnsavedChanges(true);
    };


    const createNewPlan = () => {
        setShowPlanSelectionModal(true);
    };


    const initializeNewPlan = (templatePlanId, selectedType) => {
        const currentDate = new Date().toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
        const defaultPlanName = `Draft_${currentDate}`;
        const tempId = `temp_${new Date().getTime()}`; // Create a temporary ID for the draft

        let draftPlan = {};
        const now = new Date();
        const dateSaved = now.toISOString().split('T')[0] + ' ' + now.toTimeString().split(' ')[0];

        if (selectedType === 'blank') {
            // Create a new plan with default structure and meal names
            draftPlan = {
                id: tempId,
                plans: defaultPlanStructure(),
                mealNames: defaultMealNames,
                name: defaultPlanName,
                dateSaved: dateSaved,
                isPublished: 'false',
                notes: defaultNotes()
            };
        } else if (selectedType === 'client') {
            // Use the selected client's plan
            const clientPlan = clientsPlansMap[selectedClientId].find(p => p.id === templatePlanId);
            draftPlan = {
                ...clientPlan,
                id: tempId,
                name: defaultPlanName,
                dateSaved: dateSaved,
                isPublished: 'false'

            };
        } else if (selectedType === 'template') {
            // Clone the selected template plan
            const templatePlan = templatePlans.find(p => p.id === templatePlanId);
            draftPlan = {
                ...templatePlan,
                id: tempId,
                name: defaultPlanName,
                dateSaved: dateSaved,
                isPublished: 'false'

            };
        }

        setAllPlans(sortPlansByDate([...allPlans, draftPlan]));
        setPlan(draftPlan);
        setPlanName(defaultPlanName);
        setIsNewPlan(true);
        setHasUnsavedChanges(true);
        setShowPlanSelectionModal(false);
    };


    const cancelNewPlan = () => {
        if (isNewPlan) {
            setIsNewPlan(false);

            // Remove the draft plan from the list
            const updatedPlans = allPlans.filter(p => !p.id.startsWith('temp_'));
            setAllPlans(updatedPlans);

            // Revert to the latest plan or keep it blank
            const latestPlan = updatedPlans.sort((a, b) => b.dateSaved.localeCompare(a.dateSaved))[0];
            if (latestPlan) {
                setPlan(latestPlan);
                setPlanName(latestPlan.name);
            } else {
                setPlan({ plans: defaultPlanStructure(), mealNames: defaultMealNames });
                setPlanName('');
            }
        } else if (hasUnsavedChanges && originalPlan) {
            // Revert to the original plan
            setPlan({ ...originalPlan });
            setPlanName(originalPlan.name);
            setHasUnsavedChanges(false);
            setOriginalPlan(null); // Clear the original plan
        }

    };


    const selectPlan = (selectedPlanId) => {
        if (hasUnsavedChanges) {
            showMessage(<span>
                Αποθηκεύστε ή ακυρώστε τις αλλαγές σας πριν αλλάξετε Πλάνο.
            </span>, 'error');
            return;
        }

        const selectedPlan = allPlans.find(plan => plan.id === selectedPlanId);
        if (selectedPlan) {
            setOriginalPlan({ ...selectedPlan }); // Store a copy of the original plan
            setPlan(selectedPlan);
            setPlanName(selectedPlan.name);
            setIsNewPlan(false);
            setHasUnsavedChanges(false); // Reset unsaved changes flag
        }
    };



    const deletePlan = async () => {
        if (!plan.id) {
            console.error("No plan selected for deletion");
            return;
        }

        try {
            const planDocRef = doc(firestore, collectionPath, plan.id);
            await deleteDoc(planDocRef); // Delete the plan from Firestore

            const updatedPlans = allPlans.filter(p => p.id !== plan.id); // Remove the plan from allPlans
            setAllPlans(sortPlansByDate(updatedPlans));

            // Select another plan or reset to default state
            if (updatedPlans.length > 0) {
                selectPlan(updatedPlans[0].id); // Automatically select the first plan in the list
            } else {
                setPlan({ plans: defaultPlanStructure(), mealNames: defaultMealNames, notes: defaultNotes() });
                setPlanName('');
                setIsNewPlan(false);
                setOriginalPlan(null);
            }

            showMessage(<span>Ένα πλάνο διαγράφηκε με επιτυχία!</span>, 'success');
        } catch (err) {
            console.error("Error deleting plan:", err);
            setError('Failed to delete plan');
        }
    };

    const componentRef = useRef();

    // Define a function to handle printing
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const printPlan = () => {
        const element = document.getElementById("plan-to-print");

        // Make sure the element is visible for rendering
        element.style.display = 'block';
        resizeFont();
        handlePrint();
        element.style.display = 'none';

        // const opt = {
        //     filename: 'meal_plan.pdf',
        //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
        // };

        // // Generate PDF
        // html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
        //     window.open(pdf.output('bloburl'), '_blank');

        // Hide the element again after PDF creation
        // element.style.display = 'none';
        // });
    };


    const htmlToText = (html) => {
        let tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || "";
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;


    return (

        <div className="p-4">
            <Message
                message={currentMessage}
                type={messageType}
                onDismiss={clearMessage}
            />
            {/* Display all plans or message if no plans exist */}
            <div className='flex flex-row justify-between mb-4'>
                <div className="flex flex-row justify-between scroll-container">
                    {allPlans && allPlans.length > 0 ? (
                        allPlans.map(planItem => (
                            <button
                                key={planItem.id}
                                onClick={() => selectPlan(planItem.id)}
                                className={`m-1 flex-none ${planItem.id === plan.id ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-400 hover:bg-green-600'} text-white p-2 rounded text-left text-sm w-40`}
                                title={planItem.name}
                            >
                                {planItem.name.length > 15 ? planItem.name.substring(0, 15) + '...' : planItem.name}
                                <br></br>
                                <span className='text-xs'>{planItem.dateSaved}</span>
                            </button>
                        ))
                    ) : (
                        <div>Δεν βρέθηκαν Πλάνα. Κάντε κλικ στην «Προσθήκη» για να δημιουργήσετε το πρώτο Πλάνο.</div>
                    )}
                </div>
                {/* Add New Plan Button */}
                <button
                    onClick={createNewPlan}
                    disabled={isNewPlan}
                    className={`${isNewPlan ? 'bg-gray-400' : 'bg-orange-500 hover:bg-orange-600'} text-white font-bold py-2 ml-2 px-4 text-sm rounded focus:outline-none focus:shadow-outline`}
                >
                    <span className='flex items-center'><FaPlus />&nbsp;Προσθήκη</span>
                </button>
            </div>

            {(allPlans.length > 0 || isNewPlan) && (
                <>
                    <div className='flex flex-row justify-between items-center mb-2'>
                        <div className='flex flex-row my-2 items-center'>
                            <label for="plan_name">Όνομα Πλάνου: </label>
                            <input
                                name='plan_name'
                                type="text"
                                value={planName}
                                onChange={(e) => { setPlanName(e.target.value); console.log('change it'); setHasUnsavedChanges(true) }}
                                className="p-2 ml-5 border rounded text-sm w-60"
                                placeholder="Plan Name"
                            />
                            {type === 'plans' && (
                                < select
                                    value={plan.isPublished}
                                    onChange={(e) => handlePublishChange(plan.id, e.target.value === 'true')}
                                    className="ml-5 p-2 text-sm border border-gray-300 rounded-md"
                                >
                                    <option value="true">Δημόσιο</option>
                                    <option value="false">Ιδιωτικό</option>
                                </select>
                            )}
                        </div>

                        <div>
                            {hasUnsavedChanges && (
                                <>
                                    <button onClick={cancelNewPlan} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 mx-2 rounded focus:outline-none focus:shadow-outline">
                                        <MdCancel size={16} />
                                    </button>
                                    <button
                                        onClick={savePlan}
                                        className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none mx-2 focus:shadow-outline"
                                    >
                                        <FaSave size={16} />
                                    </button>
                                </>
                            )}
                            <button
                                onClick={duplicatePlan}
                                className={`${!hasUnsavedChanges ? 'text-green-500 hover:text-green-600' : 'text-gray-300 hover:text-gray-300'} font-bold py-2 mx-2 rounded focus:outline-none focus:shadow-outline`}
                                disabled={hasUnsavedChanges}
                            >
                                <FaCopy size={16} />
                            </button>
                            <button
                                onClick={printPlan}
                                className={`${!hasUnsavedChanges ? 'text-green-500 hover:text-green-600' : 'text-gray-300 hover:text-gray-300'} font-bold py-2 mx-2 rounded focus:outline-none focus:shadow-outline`}
                                disabled={hasUnsavedChanges}
                            >
                                <FaPrint size={16} />
                            </button>
                            {!isNewPlan && (
                                <button onClick={deletePlan} className="text-red-500 hover:text-red-600 font-bold ml-2 py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                                    <FaTrash size={16} />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-row'>

                        <div className={`${isTemplatesCollapsed ? 'w-fit' : 'w-5/6'}`}>
                            <table className="min-w-full table-auto border-collapse border border-gray-300">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="w-[20px] border border-gray-300 px-4 py-2 text-left text-xs font-medium text-gray-700  tracking-wider"></th>
                                        {plan && Object.keys(plan.mealNames).map(mealType => (
                                            <th
                                                key={mealType}
                                                className="max-w-1/12 border border-gray-300 px-4 py-2 text-left text-xs font-medium text-gray-700 tracking-wider"
                                            >
                                                <div className="flex items-center justify-center">
                                                    <input
                                                        type="text"
                                                        value={plan.mealNames[mealType]}
                                                        onChange={(e) => { handleMealNameChange(mealType, e.target.value); console.log('hi chgange') }}
                                                        className="w-full border border-gray-300 px-4 py-2 text-left text-xs font-medium text-gray-700 tracking-wider rounded-md"
                                                    />
                                                    <button key={mealType} onClick={() => deleteMealType(mealType)} className="ml-2 text-red-500 hover:text-red-600 focus:outline-none focus:shadow-outline">
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            </th>
                                        ))}
                                        <th className="border border-gray-300 px-4 py-2"> {/* Empty header for the add button */}
                                            <button onClick={addMealType} className="text-green-500 hover:text-green-600">
                                                <FaPlus />
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {plan.plans.map((dayPlan, dayIndex) => (
                                        <tr key={dayIndex} className="bg-white even:bg-gray-50">
                                            <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">{dayPlan.day}</td>

                                            {Object.keys(plan.mealNames).map(mealType => (
                                                <td key={mealType} className="align-top border border-gray-300 px-4 py-2">
                                                    <div className="flex items-center justify-center">
                                                        <ReactQuill
                                                            value={dayPlan.meals[mealType]}
                                                            onChange={(content) => handleMealChange(dayIndex, mealType, content)}
                                                            modules={PlansForm.modules}
                                                            formats={PlansForm.formats}
                                                            className="react-quill-editor border-b border-gray-400 rounded bg-white text-sm"
                                                        />
                                                    </div>
                                                </td>
                                            ))}

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <table className="mt-10 min-w-full table-auto border-collapse border border-gray-300">

                                <tbody >
                                    <tr key='hey' className=" bg-gray-50 even:bg-gray-50">
                                        <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">Σημειώσεις</td>
                                        {noteOrder.map(noteKey => (
                                            <td key={noteKey} className="align-top border border-gray-300 px-4 py-2">
                                                <ReactQuill
                                                    value={plan.notes[noteKey]}
                                                    onChange={(content) => handleNoteChange(noteKey, content)}
                                                    modules={PlansForm.modules}
                                                    formats={PlansForm.formats}
                                                    className="w-full react-quill-editor border-b border-gray-400 rounded bg-white text-sm"
                                                />
                                            </td>
                                        ))}




                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={`bg-gray-100 border p-2 shadow-lg relative ${isTemplatesCollapsed ? 'w-20' : 'w-1/6'}`}>
                            {isTemplatesCollapsed ? (
                                // If collapsed, only show the toggle button
                                <button onClick={toggleTemplates} className="flex items-center py-2 px-2 justify-center text-orange-500">
                                    <p className='flex'>
                                        <FaChevronRight size={20} />
                                        <span className='mr-1'><GiMeal size={20} /></span>
                                    </p>

                                </button>
                            ) : (
                                // If not collapsed, show full content
                                <div >
                                    <button onClick={toggleTemplates} className="w-full flex py-2 px-2 justify-center">
                                        <FaChevronLeft className='mr-1 text-orange-500' size={20} />
                                        Πρότυπα
                                        <span className='ml-1 text-orange-500'><GiMeal size={20} /></span>
                                    </button>
                                    {Object.keys(groupedTemplates).map((type) => (
                                        <div key={type} className="mb-4">
                                            <button
                                                onClick={() => handleAccordionClick(type)}
                                                className="flex items-center justify-between w-full bg-gray-200 py-2 px-3 rounded-md"
                                            >
                                                <span className="font-medium text-sm">{mealTitles[type]}</span>
                                                {openSection === type ? <FaChevronDown /> : <FaChevronRight />}
                                            </button>
                                            {openSection === type && (
                                                <div className="mt-2 pl-2 border-l-2 border-orange-500">
                                                    {groupedTemplates[type].map((template) => (
                                                        <div key={template.id} className="flex flex-col mb-2 border border-gray-300 rounded-lg p-2 relative group">
                                                            <button
                                                                onClick={() => copyToClipboard(template.description)}
                                                                className="absolute top-0 right-0 text-xs opacity-70 text-orange-500 m-1"
                                                            >
                                                                <FaCopy size={16} />
                                                            </button>
                                                            <div
                                                                className="overflow-hidden group-hover:overflow-visible group-hover:whitespace-normal text-xs"
                                                                dangerouslySetInnerHTML={{ __html: template.description }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}                        </div>
                            )}
                        </div>
                    </div>


                </>
            )}




            {showPlanSelectionModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <h3 className="text-lg bg-orange-500 text-white leading-6 font-medium px-4 py-5">Επέλεξε τύπο Πρότυπου Πλάνου</h3>

                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <div className="mt-2">

                                            {/* Radio Buttons for Template Type */}
                                            <div className="mb-4">
                                                <label className="inline-flex items-center">
                                                    <input type="radio" name="templateType" value="blank" checked={selectedTemplateType === 'blank'} onChange={(e) => setSelectedTemplateType(e.target.value)} />
                                                    <span className="ml-2">Κένο</span>
                                                </label>
                                                <label className="inline-flex items-center ml-5">
                                                    <input type="radio" name="templateType" value="template" checked={selectedTemplateType === 'template'} onChange={(e) => setSelectedTemplateType(e.target.value)} />
                                                    <span className="ml-2">Πρότυπο</span>
                                                </label>
                                                <label className="inline-flex items-center ml-5">
                                                    <input type="radio" name="templateType" value="client" checked={selectedTemplateType === 'client'} onChange={(e) => setSelectedTemplateType(e.target.value)} />
                                                    <span className="ml-2">Διαιτώμενου</span>
                                                </label>
                                            </div>

                                            {/* Client Dropdown */}

                                            {selectedTemplateType === 'client' && (
                                                <div className="mb-4">
                                                    <select value={selectedClientId} className='shadow border rounded w-full py-2 px-3 text-gray-700 text-sm focus:outline-none focus:shadow-outline' onChange={e => setSelectedClientId(e.target.value)}>
                                                        <option value="">Επέλεγε Διαιτώμενο</option>
                                                        {Object.keys(clientsPlansMap).map(clientId => (
                                                            <option key={clientId} value={clientId}>{clientId}</option>
                                                        ))}
                                                    </select>


                                                </div>
                                            )}

                                            {/* Template Plan Buttons */}
                                            {selectedTemplateType === 'template' && templatePlans.map(planItem => (
                                                <button
                                                    key={planItem.id}
                                                    onClick={() => initializeNewPlan(planItem.id, selectedTemplateType)}
                                                    className="w-full mr-2 inline-flex text-left justify-center my-1 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                                                >
                                                    <span className='text-sm'>{planItem.name} <br></br>
                                                    </span>
                                                </button>
                                            ))}

                                            {/* Client Plan Buttons */}

                                            {selectedTemplateType === 'client' && selectedClientId && clientsPlansMap[selectedClientId].map(plan => (
                                                <button
                                                    key={plan.id}
                                                    onClick={() => initializeNewPlan(plan.id, selectedTemplateType)}
                                                    className="w-full mr-2 inline-flex text-left justify-center my-1 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"

                                                >
                                                    <span className='text-sm'>{plan.name} <br></br>
                                                        <span className='text-xm'>
                                                            {plan.dateSaved}

                                                        </span>
                                                    </span>
                                                </button>
                                            ))}

                                            {/* Blank Plan Button */}
                                            {selectedTemplateType === 'blank' && (
                                                <button
                                                    onClick={() => initializeNewPlan('blank', selectedTemplateType)}
                                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                                >
                                                    Κενό Πλάνο
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    onClick={() => setShowPlanSelectionModal(false)}
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Ακύρωση
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div id="plan-to-print" ref={componentRef} className=' p-2' style={{ display: "none", width: "297mm", height: "210mm", padding: "5mm" }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '200mm' }}> {/* Adjusted height to account for header and padding */}
                    <div id='part-header' className='flex flex-row justify-center my-2 w-full'>
                        <img src={logo} alt="Logo" className="h-12 w-auto object-contain mr-3" />

                        <p className='text-xl font-semibold text-green-600 flex items-center mb-4 ml-8'>Εβδομαδιαίο πρόγραμμα διατροφής</p>
                    </div>
                    <table id="plan-table" style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '5mm', flex: '1' }}>
                        <thead>
                            <tr>
                                <th className='px-2 plan-header bg-orange-500 text-white border border-gray-50 py-1 font-semibold rounded-md text-center' style={{ fontSize: '12px' }}></th>
                                {Object.keys(plan.mealNames).map(mealType => (
                                    <th key={mealType} className='plan-header bg-orange-500 text-white border border-gray-50 py-1 font-semibold rounded-md text-center' style={{ fontSize: '12px' }}>{plan.mealNames[mealType]}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {plan.plans.map((dayPlan, dayIndex) => (
                                <tr key={dayIndex} className='day-row'>
                                    <td className='plan-header text-gray-700 font-semibold border bg-orange-500 px-2 text-white py-1 rounded-md text-center' style={{ fontSize: '12px' }}>{dayPlan.day}</td>
                                    {Object.keys(plan.mealNames).map(mealType => (
                                        <td className='plan-content bg-white rounded-md border py-1 px-1 text-center' key={mealType}
                                            dangerouslySetInnerHTML={{ __html: dayPlan.meals[mealType] }}>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <table id="notes-table" style={{ width: '100%', borderCollapse: 'collapse', flex: '0' }}>
                        <tbody>
                            <tr>
                                <td className='note-header bg-gray-400 text-white rounded-md px-2 py-1 font-semibold' style={{ fontSize: '12px' }} >Σημειώσεις</td>
                                {plan.notes && noteOrder.map(noteKey => (
                                    <td key={noteKey} className='note-content bg-white rounded-md border px-1 py-1 text-center' style={{ fontSize: '9px' }}
                                        dangerouslySetInnerHTML={{ __html: plan.notes[noteKey] }}>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div >

    );
};

export default PlansForm;
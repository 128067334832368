import React from 'react';

const Tabs = ({ activeTab, setActiveTab }) => {
  const tabClass = "px-4 py-2 rounded-t-lg text-md font-medium focus:outline-none transition duration-200";
  const activeTabClass = "bg-white text-gray-800 border-b-2 border-orange-500";

  return (
    <div className="flex border-b">
      <button 
        onClick={() => setActiveTab('profile')} 
        className={`${tabClass} ${activeTab === 'profile' ? activeTabClass : 'text-gray-500'}`}
      >
        Προφίλ
      </button>
      <button 
        onClick={() => setActiveTab('history')} 
        className={`${tabClass} ${activeTab === 'history' ? activeTabClass : 'text-gray-500'}`}
      >
        Ιστορικό
      </button>
      <button 
        onClick={() => setActiveTab('measurements')} 
        className={`${tabClass} ${activeTab === 'measurements' ? activeTabClass : 'text-gray-500'}`}
      >
        Μετρήσεις
      </button>
      {/* <button 
        onClick={() => setActiveTab('equivalents')} 
        className={`${tabClass} ${activeTab === 'equivalents' ? activeTabClass : 'text-gray-500'}`}
      >
        Ισοδύναμα
      </button> */}
      <button 
        onClick={() => setActiveTab('plans')} 
        className={`${tabClass} ${activeTab === 'plans' ? activeTabClass : 'text-gray-500'}`}
      >
        Πλάνα
      </button>
    </div>
  );
};

export default Tabs;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import Header from '../components/Header/Header';
import Tabs from '../components/ClientsDetails/Tabs';
// import EquivalentsForm from '../components/ClientsDetails/EquivalentsForm'; // Import the EquivalentsForm
import ClientProfileForm from '../components/ClientsDetails/ClientProfileForm';
import { IoIosArrowForward } from "react-icons/io";
import HistoryForm from '../components/ClientsDetails/HistoryForm';
import MeasurementsForm from '../components/ClientsDetails/MeasurementsForm'; // Import the MeasurementsForm
import { calculate_ib } from '../functions/Functions';
import PlansForm from '../components/ClientsDetails/PlansForm';

const calculateAge = (birthday) => {
  const birthDate = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const ClientInfo = ({ client }) => {
  const age = client.birthday ? calculateAge(client.birthday) : 'N/A';
  return (
    <div className='text-sm'>
      <h2 className="text-md font-semibold mb-2">Βασικές Πληροφορίες</h2>
      <table className="w-fit divide-y divide-gray-200 mb-4">
        <tbody className="divide-y divide-gray-200">
          <tr>
            <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Φυλο</td>
            <td className={`px-3 py-2 whitespace-nowrap ${client.gender === 'Male' ? 'text-blue-500' : client.gender === 'Female' ? 'text-red-400' : ''}`}>{client.gender}</td>
          </tr>
          <tr>
            <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Κατασταση</td>
            <td className={`px-3 py-2 whitespace-nowrap ${client.status === 'Active' ? 'text-green-600' : client.status === 'Inactive' ? 'text-red-600' : ''}`}>{client.status}</td>

          </tr>
          <tr>
            <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Ηλικια</td>
            <td className="px-3 py-2 whitespace-nowrap">{age}  ετών</td>
          </tr>
          <tr>
            <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Υψος</td>
            <td className="px-3 py-2 whitespace-nowrap">{client.height} cm</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const ClientWeight = ({ client, measurements }) => {
  // Determine the first and latest measurements
  const firstMeasurement = measurements.length > 0 ? measurements[0].weight : "-";
  const latestMeasurement = measurements.length > 0 ? measurements[measurements.length - 1].weight : "-";

  return (
    <div className='text-sm'>
      <h2 className="text-md font-semibold mb-2">Bάρος</h2>
      <table className="w-fit divide-y divide-gray-200 mb-4">
        <tbody className="divide-y divide-gray-200">
          <tr>
            <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Στoχος</td>
            <td className='px-3 py-2 whitespace-nowrap'>{client.target} Kg</td>
          </tr>
          <tr>
            <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Ιδανικo</td>
            <td className="px-3 py-2 whitespace-nowrap">{calculate_ib(client)} Kg</td>

          </tr>
          <tr>
            <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Αρχικo</td>
            <td className="px-3 py-2 whitespace-nowrap">{firstMeasurement} Kg</td>
          </tr>
          <tr>
            <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Τωρινo</td>
            <td className="px-3 py-2 whitespace-nowrap">{latestMeasurement} Kg</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const EquivalentsSummary = ({ equivalents }) => {
  if (!equivalents) {
    // Render some placeholder or message indicating no data is available
    return (
      <div>
        <h2 className="text-md font-semibold mb-2">Τελευταία Ισοδύναμα Συνοπτικά</h2>
        <div className="grid gap-4 text-sm">
          <p className='text-gray-700'>Δεν υπάρχουν δεδομένα μετρήσεων</p>
        </div>
      </div>
    );
  }
  const carbsPercent = Number(equivalents.total_percent_carbohydrates) || 0;
  const proteinsPercent = Number(equivalents.total_percent_proteins) || 0;
  const fatPercent = Number(equivalents.total_percent_fat) || 0;

  const total_percent = (carbsPercent + proteinsPercent + fatPercent).toFixed(0);

  const carbsGr = Number(equivalents.total_carbohydrates) || 0;
  const proteinsGr = Number(equivalents.total_proteins) || 0;
  const fatGr = Number(equivalents.total_fat) || 0;

  const total_Gr = carbsGr + proteinsGr + fatGr;
  const total_Cal = Number(equivalents.total_calories) || 0;
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">Τελευταία Ισοδύναμα Συνοπτικά</h2>
      <div className="grid gap-4 text-sm">
        <table className="w-fit divide-y divide-gray-200 mb-4">
          <tbody className="divide-y divide-gray-200">
            <tr>
              <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Υδατανθρακες</td>
              <td className="px-3 py-2 whitespace-nowrap">{carbsPercent} %</td>
              <td className="px-3 py-2 whitespace-nowrap">{carbsGr} gr</td>
              <td className="px-3 py-2 whitespace-nowrap">{carbsGr * 4} cal</td>
            </tr>
            <tr>
              <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">ΠΡΩΤΕΪΝΕΣ</td>
              <td className="px-3 py-2 whitespace-nowrap">{proteinsPercent} %</td>
              <td className="px-3 py-2 whitespace-nowrap">{proteinsGr} gr</td>
              <td className="px-3 py-2 whitespace-nowrap">{proteinsGr * 4} cal</td>
            </tr>
            <tr>
              <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Λιπος</td>
              <td className="px-3 py-2 whitespace-nowrap">{fatPercent} %</td>
              <td className="px-3 py-2 whitespace-nowrap">{fatGr} gr</td>
              <td className="px-3 py-2 whitespace-nowrap">{fatGr * 9} cal</td>
            </tr>
            <tr>
              <td className="px-3 py-2 text-left text-xs bg-gray-50 font-medium text-orange-500 uppercase tracking-wider">Συνολο</td>
              <td className="px-3 py-2 whitespace-nowrap">{total_percent} %</td>
              <td className="px-3 py-2 whitespace-nowrap">{total_Gr} gr</td>
              <td className="px-3 py-2 whitespace-nowrap">{total_Cal} cal</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ClientNotes = ({ note, onNoteChange, onSave, onCancel, isEdited, showSavedMessage }) => {
  return (
    <div>
      <h2 className="text-md font-semibold mb-2">Γενικές Σημειώσεις</h2>
      {showSavedMessage && (
        <div className="mt-3 text-green-600">
          Note saved successfully!
        </div>
      )}
      <textarea
        className="w-full p-2 border rounded"
        placeholder='Γράψε τις σημειώσεις σου'
        value={note}
        onChange={onNoteChange}
        rows={4}
      />
      {isEdited && (
        <div className='float-right'>
          <button
            onClick={onCancel}
            className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={onSave}
            className="py-2 px-4 ml-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};


const ClientDetails = () => {
  const { id } = useParams();
  const [client, setClient] = useState(null);
  // const [equivalents, setEquivalents] = useState(null);
  const [activeTab, setActiveTab] = useState('profile');
  const [editedNote, setEditedNote] = useState('');
  const [isNoteEdited, setIsNoteEdited] = useState(false);
  const [showNoteSavedMessage, setShowNoteSavedMessage] = useState(false);
  const [historyData, setHistoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [measurements, setMeasurements] = useState([]);
  const latestMeasurementEquivalents = measurements.length > 0 ? measurements[measurements.length - 1].equivalents : null;

  useEffect(() => {

    setLoading(true);
    const fetchClient = async () => {
      try {
        const clientDocRef = doc(firestore, 'Clients', id);
        const docSnapshot = await getDoc(clientDocRef);

        if (docSnapshot.exists()) {
          setClient(docSnapshot.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error('Error fetching client:', error);
      }
    };

    fetchClient();

    // const fetchEquivalents = async () => {
    //   // Logic to fetch equivalents data from Firestore
    //   // Assume equivalents data is stored in a 'Equivalents' collection
    //   const equivalentsDocRef = doc(firestore, 'Equivalents', id);
    //   const equivalentsSnapshot = await getDoc(equivalentsDocRef);
    //   if (equivalentsSnapshot.exists()) {
    //     setEquivalents(equivalentsSnapshot.data());
    //   } else {
    //     console.log("No such document for equivalents!");
    //   }
    // };

    // fetchEquivalents();
    // Fetch history data
    const fetchHistory = async () => {
      const historyDocRef = doc(firestore, 'History', id);
      const historySnapshot = await getDoc(historyDocRef);
      if (historySnapshot.exists()) {
        setHistoryData(historySnapshot.data());
      } else {
        console.log("No such document for history!");
      }
    };

    fetchHistory();

    const fetchMeasurements = async () => {
      try {
        const measurementsRef = collection(firestore, "Clients", id, "Measurements");
        const querySnapshot = await getDocs(measurementsRef);
        const fetchedMeasurements = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setMeasurements(fetchedMeasurements);
      } catch (error) {
        console.error("Error fetching measurements:", error);
      }
    };

    fetchMeasurements();
    setLoading(false);

  }, [id]);

  const handleSaveHistory = async (updatedHistory) => {
    try {
      const historyDocRef = doc(firestore, 'History', id);
      await updateDoc(historyDocRef, updatedHistory);
      setHistoryData(updatedHistory);
      console.log('History data updated successfully');
    } catch (error) {
      console.error('Error updating history:', error);
    }
  };

  const handleNoteChange = (e) => {
    setEditedNote(e.target.value);
    setIsNoteEdited(true);
  };

  const handleSaveNote = async () => {
    try {
      const clientDocRef = doc(firestore, 'Clients', id);
      await updateDoc(clientDocRef, { note: editedNote });

      setClient({ ...client, note: editedNote });
      setIsNoteEdited(false);
      setShowNoteSavedMessage(true);
      setTimeout(() => setShowNoteSavedMessage(false), 5000); // Hide message after 5 seconds
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const handleCancelNote = () => {
    setEditedNote(client?.note || '');
    setIsNoteEdited(false);
  };


  const handleSaveProfile = async (updatedProfile) => {
    try {
      const equivalentsDocRef = doc(firestore, 'Clients', id);
      await updateDoc(equivalentsDocRef, updatedProfile);

      // Update local state
      setClient(updatedProfile);
      console.log('Clients data updated successfully');
    } catch (error) {
      console.error('Error updating equivalents:', error);
    }
  };

  return (
    <div className="relative min-h-screen bg-gray-100">
      <Header />
      <div className="p-4 max-w-8xl mx-auto">
        <span className='flex items-center text-sm'>
          <p>
            <a className='text-orange-500' href='/workspace'>Workspace</a>
          </p>
          <p className='mx-1'><IoIosArrowForward /></p>
          <p> {client?.name}</p>
        </span>
        {!loading && client && historyData && (
          <>
            <h1 className="text-2xl font-medium text-gray-800 my-2">{client?.name || 'Loading...'}</h1>

            <div className="bg-white p-6 rounded-lg shadow mb-6">
              <div className="flex gap-10">
                <div className="">
                  <ClientInfo client={client} />
                </div>
                <div className="">
                  <ClientWeight client={client} measurements={measurements} />
                </div>
                <div className="">
                  <EquivalentsSummary equivalents={latestMeasurementEquivalents} />
                </div>
                <div className="flex-1">
                  <ClientNotes
                    note={editedNote || client?.note || ''}
                    onNoteChange={handleNoteChange}
                    onSave={handleSaveNote}
                    onCancel={handleCancelNote}
                    isEdited={isNoteEdited}
                    showSavedMessage={showNoteSavedMessage}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

        <div className="bg-white p-6 rounded-lg shadow mt-4">
          {activeTab === 'profile' && <ClientProfileForm initialValues={client} onSave={handleSaveProfile} />}
          {/* {activeTab === 'equivalents' && <EquivalentsForm initialValues={equivalents} onSave={handleSaveEquivalents} />} */}
          {activeTab === 'history' && <HistoryForm initialValues={historyData} onSave={handleSaveHistory} />}
          {activeTab === 'measurements' && <MeasurementsForm clientId={id} clientdata={client} measurements={measurements} setMeasurements={setMeasurements} />
          }
          {activeTab === 'plans' && <PlansForm collectionPath={`Clients/${id}/Plans`} type={'plans'} />}

        </div>
      </div>
    </div>
  );
};

export default ClientDetails;

import React, { useEffect } from 'react';

const Message = ({ message, type, onDismiss }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onDismiss();
        }, 5000);

        return () => clearTimeout(timer);
    }, [message, onDismiss]);

    if (!message) return null;

    return (
        <div className={`fixed top-5 right-5 p-4 border z-40 rounded-lg shadow-md transition-opacity duration-300 text-sm ${type === 'success' ? 'bg-green-100 border-green-400 text-green-700' : 'bg-red-100 border-red-400 text-red-700'
            }`}>
            {message}
        </div>
    );
};

export default Message;